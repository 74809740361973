import humps from 'humps'

import Route from 'src/network/Route'

import { FlockjayProvider } from 'src/network/FlockjayProvider'
import { NoAuthProvider } from 'src/network/NoAuthProvider'
import MethodTypes from 'src/models/enums/MethodTypes'
import { clearFalseyValues, toBase64 } from 'src/utils/format'
import { getFeedContentData } from 'src/utils/content'
import { FeedComment } from 'src/models/FeedComment'
import { FeedPost } from 'src/models/FeedPost'
import { Author } from 'src/models/Author'
import { Course } from 'src/models/Course'
import { LearningPath, LearningPathContent } from 'src/models/LearningPath'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { DraftContentType } from 'src/pages/Draft'
import { LearningContentType } from 'src/utils/content'
import { CommentableItem } from 'src/components/Feed/FeedComments'
import { Asset } from 'src/models/Asset'
import { ConsumptionChartContentType } from 'src/components/Dashboard/ConsumptionChart'
import { QueueElement } from 'src/models/QueueItem'
import { Hub, HubElement } from 'src/models/Hub'
import { Page } from 'src/models/Page'
import { CSVDataExportType } from 'src/components/Common/Button'
import { FjEvent } from 'src/models/FjEvent'
import { OpportunityData } from 'src/components/Dashboard/OpportunityChart'
import { TeamTab } from 'src/pages/Team'
import { handleCSVExportResponse } from 'src/utils/fileOperation'
import { sharedDataStore } from 'src/store/DataStore'
import { titleCase } from 'src/utils/format'
import { User } from 'src/models/User'
import { Group } from 'src/models/Group'
import { UserCertificate } from 'src/models/UserCertificate'
import { AxiosResponse } from 'axios'
const FULL_SEARCH_RESPONSE_LENGTH = 15

function postUnsubscribe(info: { email: string }) {
  return FlockjayProvider(new Route(MethodTypes.POST, '/api/unsubscribe/', info))
}

function postEvent(reqData: { eventType: string; user?: string; data?: object } | Array<any>) {
  return NoAuthProvider(new Route(MethodTypes.POST, '/api/events/', reqData))
}

const uploadFile = async (file: File, extractVideoMetadata: boolean = false) => {
  const content = (await toBase64(file, true)) as string
  const requestData = { filename: file.name, content }
  let path = '/api/upload_file/'
  if (extractVideoMetadata) path = `${path}?extract_video_metadata=true`
  return FlockjayProvider(new Route(MethodTypes.POST, path, requestData))
}

const getQuickLookData = () => {
  return FlockjayProvider(new Route(MethodTypes.GET, '/feed/quick_look/'))
}

const getDraftData = async (queryParams = {}, abortController?: AbortController) => {
  const { data } = await FlockjayProvider(
    new Route(MethodTypes.GET, `/feed/drafts/`, {}, {}, queryParams, abortController)
  )
  const hubs = Hub.fromData(data.hubs)
  const courses = Course.fromData(data.courses)
  const learningPaths = LearningPath.fromData(data.learningPaths)
  const playlists = FeedPlaylist.fromData(data.playlists)
  const pages = Page.fromData(data.pages)
  return [...pages, ...hubs, ...courses, ...learningPaths, ...playlists].sort(
    (a: DraftContentType, b: DraftContentType) => b.createdAt.valueOf() - a.createdAt.valueOf()
  )
}

const getFavourites = async (params: Object = {}, abortController?: AbortController) => {
  const {
    data: { results, next, previous, count },
  } = await FlockjayProvider(
    new Route(MethodTypes.GET, '/feed/favourites/', undefined, undefined, params, abortController)
  )
  return {
    results: results.map((result) => getFeedContentData(result.contentData, result.contentType)),
    next,
    previous,
    count,
  }
}

const getFullSearch = async (params: Object = {}, abortController?: AbortController) => {
  const {
    data: { results, next, previous, count, customFields },
  } = await FlockjayProvider(
    new Route(MethodTypes.GET, '/feed/full_search/', undefined, undefined, params, abortController)
  )
  return {
    results: results.map((result) => getFeedContentData({ ...result.contentData }, result.contentType)),
    next: results.length === FULL_SEARCH_RESPONSE_LENGTH ? next : null,
    previous,
    count,
    customFields,
  }
}

const getAISearch = async (params: Object = {}, abortController?: AbortController) => {
  const {
    data: { results, next, previous, count, customFields },
  } = await FlockjayProvider(
    new Route(MethodTypes.GET, '/feed/ai_search/', undefined, undefined, params, abortController)
  )
  return {
    results: results.map((result) => getFeedContentData({ ...result.contentData }, result.contentType)),
    next,
    previous,
    count,
    customFields,
  }
}

const searchContent = async (queryParams: Object) => {
  const { data } = await FlockjayProvider(new Route(MethodTypes.GET, '/feed/search/', {}, {}, queryParams))

  return data
}

const searchPromotedContent = async (searchStr: string) => {
  const {
    data: { learningContents },
  } = await FlockjayProvider(new Route(MethodTypes.GET, `/feed/promoted_search/${searchStr}/`, {}, {}, {}))

  return learningContents.map((learningContent: LearningPathContent) =>
    getFeedContentData(learningContent.contentData, learningContent.contentType)
  )
}

const addToPromotedSearch = async (data) => {
  return FlockjayProvider(new Route(MethodTypes.POST, '/feed/promoted_search/add_to_priority_search_result/', data))
}

const removeFromPromotedSearch = async (data) => {
  return FlockjayProvider(
    new Route(MethodTypes.POST, '/feed/promoted_search/remove_from_priority_search_result/', data)
  )
}

const addContentToContainer = async (
  containerClass: typeof LearningPath | typeof FeedPlaylist | typeof Hub,
  containerId: string,
  dataPayload?: object
) => {
  const { method, path } = containerClass.formatActionEndpoint(containerId, undefined, 'add')
  const { data } = await FlockjayProvider(new Route(method, path, dataPayload))
  return containerClass.fromData(data)
}

const addContentToHub = async (hubId: string, objId: string, contentType: LearningContentType) => {
  const { data } = await FlockjayProvider(
    new Route(MethodTypes.PATCH, `/feed/hub/${hubId}/add/`, {
      learningContent: {
        objectId: objId,
        contentType: contentType,
      },
    })
  )

  return data
}

const getConsumptionData = async (queryParams: Object) => {
  const { data } = await FlockjayProvider(
    new Route(MethodTypes.GET, `/feed/consumption/`, {}, {}, queryParams, undefined, { retries: 0 })
  )
  return data
}

const getTaskCompletionData = async (queryParams: Object) => {
  const { data } = await FlockjayProvider(
    new Route(MethodTypes.GET, `feed/queue/completion_dashboard/`, {}, {}, queryParams, undefined, { retries: 0 })
  )
  return data
}

const getCourseCompletionData = async (courseId: string, queryParams: Object) => {
  const { data } = await FlockjayProvider(
    new Route(MethodTypes.GET, `/api/course/${courseId}/progress/`, {}, {}, queryParams)
  )
  return data
}

const getLearningPathCompletionData = async (learningPathId: string, queryParams: Object) => {
  const { data } = await FlockjayProvider(
    new Route(MethodTypes.GET, `/feed/learningpath/${learningPathId}/progress/`, {}, {}, queryParams)
  )
  return data
}

const getCollectionCompletionData = async (playlistId: string, queryParams: Object) => {
  const { data } = await FlockjayProvider(
    new Route(MethodTypes.GET, `/feed/playlist/${playlistId}/progress/`, {}, {}, queryParams)
  )
  return data
}

const getPromptCompletionData = async (promptId: string, queryParams: Object) => {
  const { data } = await FlockjayProvider(
    new Route(MethodTypes.GET, `/feed/prompt/${promptId}/progress/`, {}, {}, queryParams)
  )
  return data
}

const getAssetCompletionData = async (assetId: string, queryParams: Object) => {
  const { data } = await FlockjayProvider(
    new Route(MethodTypes.GET, `/feed/assets/${assetId}/progress/`, {}, {}, queryParams)
  )
  return data
}

const getFeedPostCompletionData = async (postId: string, queryParams: Object) => {
  const { data } = await FlockjayProvider(
    new Route(MethodTypes.GET, `/feed/posts/${postId}/progress/`, {}, {}, queryParams)
  )
  return data
}

const updateLearningPathContentProgress = async (learninPathId: string, contentId: string) => {
  return await FlockjayProvider(
    new Route(MethodTypes.PATCH, `/feed/learningpath/${learninPathId}/content/${contentId}/mark_as_completed/`)
  )
}

const markAllNotificationsAsRead = async () => {
  return await FlockjayProvider(new Route(MethodTypes.POST, '/feed/notifications/mark_all_as_read/'))
}

const getLikedBy = async (obj: CommentableItem, comment?: FeedComment) => {
  let url
  if (comment) {
    url = FeedComment.formatActionEndpoint(
      comment.id,
      { parentObjectId: obj.id, parentObjectType: FeedComment.getParentObjectType(obj) },
      'liked_by'
    ).path
  } else {
    if (obj instanceof FeedPost) {
      url = FeedPost.formatActionEndpoint(obj.id, {}, 'liked_by').path
    } else if (obj instanceof Asset) {
      url = Asset.formatActionEndpoint(obj.id, {}, 'liked_by').path
    }
  }

  const { data } = await FlockjayProvider(new Route(MethodTypes.GET, url))
  return Author.fromData(data)
}

const fetchUserReportCard = async (userId: string) => {
  const { data } = await FlockjayProvider(new Route(MethodTypes.GET, `/api/users/${userId}/report_card/`))
  return data
}

const fetchUserConsumptionData = async (
  userId: string,
  contentType: ConsumptionChartContentType
): Promise<Array<QueueElement>> => {
  const url = `/feed/consumption/user_report/?user_id=${userId}&content_type=${contentType}`
  const { data } = await FlockjayProvider(new Route(MethodTypes.GET, url))
  return getFeedContentData(data, contentType as LearningContentType)
}

const getAnalyticsCSVData = async (type: CSVDataExportType, id?: string, params?: Object) => {
  let queryParams = params ? humps.decamelizeKeys(params) : {}
  let path = ''
  if (['consumption', 'activity'].includes(type)) path = `/feed/${type}/export_to_csv/`
  else if (type === 'tasks') path = '/feed/queue/export_to_csv/'
  else path = `/${type === 'course' ? 'api' : 'feed'}/${type}/${id}/export_progress_to_csv/`
  const res = await FlockjayProvider(new Route(MethodTypes.GET, path, {}, {}, queryParams, undefined, { retries: 0 }))
  const fileName = `${sharedDataStore.user.company.name}_flockjay_${titleCase(type)}${id ? `_${id}` : ''}`
  await handleCSVExportResponse(res, fileName)
}

const exportTeamDetailCSV = async (type: TeamTab, filters?: Record<string, any>) => {
  const exportDetailsTypesMap = {
    members: {
      exportCSVFields: User.EXPORT_TO_CSV_FIELDS,
      path: User.formatActionEndpoint().path,
    },
    groups: {
      exportCSVFields: Group.EXPORT_TO_CSV_FIELDS,
      path: Group.formatActionEndpoint().path,
    },
  }
  const { exportCSVFields, path } = exportDetailsTypesMap[type]
  const queryParams = { expand: exportCSVFields, ...filters }
  const fileName = `${sharedDataStore.user.company.name}_flockjay_${titleCase(type)}`

  const res = await FlockjayProvider(new Route(MethodTypes.GET, path, {}, { Accept: 'text/csv' }, queryParams))
  await handleCSVExportResponse(res, fileName)
}

const downloadFile = async (fileUrl: string, filename: string) => {
  const link = document.createElement('a')
  link.download = filename
  link.href = fileUrl
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const sendDemoSlackMessage = (data: { email: string; msg: string }) => {
  return FlockjayProvider(new Route(MethodTypes.POST, '/feed/send_demo_slack_message', data))
}

const fetchLearningContents = async (hubId: string): Promise<HubElement[]> => {
  const url = `/feed/hub/${hubId}/learning_contents/`
  const { data } = await FlockjayProvider(new Route(MethodTypes.GET, url))
  return data?.['learningContents']
    ? data['learningContents'].map((c) => getFeedContentData(c.contentData, c.contentType))
    : []
}

const getPresignedUrl = async (data: object) => {
  return FlockjayProvider(new Route(MethodTypes.POST, '/feed/aws_presigned_url', data))
}

const getSharedLinksDashboardData = async (queryParams: object, headers?: object): Promise<AxiosResponse> => {
  return await FlockjayProvider(
    new Route(MethodTypes.GET, 'feed/sharedcontent_dashboard/', undefined, headers, queryParams, undefined, {
      retries: 0,
    })
  )
}

const getOpportunityDashboadData = async (startDate?: string, endDate?: string, groupId?: string) => {
  const { data } = await FlockjayProvider(
    new Route(
      MethodTypes.GET,
      '/feed/opportunity_dashboard/',
      undefined,
      undefined,
      clearFalseyValues({ start_date: startDate, end_date: endDate, group_id: groupId }),
      undefined,
      { retries: 0 }
    )
  )
  return data as OpportunityData[]
}

const getAISummary = async (query: string) => {
  const { data } = await FlockjayProvider(
    new Route(MethodTypes.GET, `/feed/ai_search/summary/?search=${encodeURIComponent(query)}`)
  )
  return data['summary']
}

const getSearchAutocompleteValues = async (queryParams: object, headers?: object) => {
  const { path } = FjEvent.formatActionEndpoint(undefined, undefined, 'search_autocomplete')
  return await FlockjayProvider(
    new Route(MethodTypes.GET, path, undefined, headers, queryParams, undefined, { retries: 0 })
  )
}

const getCertificateAnalyticsData = async (queryParams: object = {}, headers?: object) => {
  const { path } = UserCertificate.formatActionEndpoint()
  const { data } = await FlockjayProvider(
    new Route(MethodTypes.GET, path, undefined, headers, {
      fields: 'id,content_type,user,pdf_url,created_at,learning_content',
      ...queryParams,
    })
  )
  return data
}

export const APIProvider = {
  postUnsubscribe,
  postEvent,
  uploadFile,
  searchContent,
  searchPromotedContent,
  addToPromotedSearch,
  removeFromPromotedSearch,
  addContentToContainer,
  addContentToHub,
  updateLearningPathContentProgress,
  getFavourites,
  getFullSearch,
  getAISearch,
  markAllNotificationsAsRead,
  getQuickLookData,
  getDraftData,
  getLikedBy,
  getConsumptionData,
  getTaskCompletionData,
  getCourseCompletionData,
  getLearningPathCompletionData,
  getCollectionCompletionData,
  getPromptCompletionData,
  getAssetCompletionData,
  getFeedPostCompletionData,
  getAnalyticsCSVData,
  exportTeamDetailCSV,
  downloadFile,
  fetchUserReportCard,
  fetchUserConsumptionData,
  sendDemoSlackMessage,
  fetchLearningContents,
  getPresignedUrl,
  getSharedLinksDashboardData,
  getOpportunityDashboadData,
  getAISummary,
  getSearchAutocompleteValues,
  getCertificateAnalyticsData,
}
