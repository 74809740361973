import { DataObject } from 'src/models/DataObject'
import { computed, makeObservable } from 'mobx'
import { Company } from 'src/models/Company'

export class Author extends DataObject {
  static OVERRIDE_MAPPINGS = {
    authorID: (data) => data.id,
    authorName: 'username',
    batch: 'cohort',
    manager: (data) => (data.manager ? Author.fromData(data.manager) : undefined),
    company: ({ company }) => (company ? Company.fromData(company) : undefined),
  }

  authorID: string = ''
  id: string = ''
  authorName: string = ''
  fullName: string = ''
  email: string = ''
  role: string = ''
  batch: number = 0
  company?: Company
  profileImgUrl: string = ''
  status: string = ''
  companyId: string = ''
  jobPosition: string = ''
  manager?: Author

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get displayPic() {
    return this.profileImgUrl
  }

  getFirstName = () => {
    return this.fullName && this.fullName.split(' ')[0]
  }
  getLastName = () => {
    return this.fullName && this.fullName.split(' ')[1]
  }

  getInitials = () => {
    const firstName = this.getFirstName() || ''
    const lastName = this.getLastName() || ''
    return (firstName[0] || '') + (lastName[0] || '')
  }

  formatWorkInfo = () => this.jobPosition || ''
}
