import React from 'react'
import { Avatar, AvatarProps, Popover, PopoverProps } from 'antd'
import { ContainerDiv } from 'src/components/Common/Cards'
import { FjText } from 'src/components/Common/Text'
import { Colors } from 'src/constants/colors'
import { Author } from 'src/models/Author'
import { User } from 'src/models/User'
import { sharedDataStore } from 'src/store/DataStore'
import styled from 'styled-components'

interface FjAvatarProps extends AvatarProps {
  author: Author | User
  showPopover?: boolean
  popoverProps?: Omit<PopoverProps, 'content' | 'showArrow'>
  showEmail?: boolean
  showWorkInfo?: boolean
}

const colors = ['#91D5FF', '#ECA0FA', '#B89AEB', '#75E1C7', '#7CD37A', '#BFCA7E', '#E6CE4B', '#FFB54D', '#B89AEB']

const getRGBHexCode = (str: string) => {
  if (!str) return colors[0]
  const hash = Array.from(str).reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0)
  return colors[Math.abs(hash) % colors.length]
}

export const FjAvatar = ({
  style,
  author,
  showPopover = true,
  showEmail = false,
  showWorkInfo = true,
  popoverProps = {},
  ...props
}: FjAvatarProps) => {
  const getFontSize = () => {
    if (typeof props.size === 'number') return props.size / 2
    else if (props.size === 'small') return 12
    else if (props.size === 'default') return 16
    else if (props.size === 'large') return 20
    return 16
  }

  const showPendingStatus = author?.status === 'pending' && sharedDataStore.user.isFaasAdminOrManager()
  const fullName = author.fullName
  const userInitials = author.getInitials()

  const backgroundColor = getRGBHexCode(fullName)

  return (
    <Popover
      placement="top"
      showArrow={!!author}
      content={
        showPopover && author ? (
          <ContainerDiv display="flex" flexDirection="column" alignItems="flex-start">
            {showPendingStatus ? (
              <FjText fontSize="small">The user is pending activation.</FjText>
            ) : (
              <>
                <FjText fontSize="small">{author.fullName}</FjText>
                {showEmail ? <FjText fontSize="small">{author.email}</FjText> : null}
                {showWorkInfo && author.formatWorkInfo ? (
                  <FjText fontSize="small" color={Colors.gray}>
                    {author.formatWorkInfo()}
                  </FjText>
                ) : null}
              </>
            )}
          </ContainerDiv>
        ) : null
      }
      {...popoverProps}
    >
      <Avatar
        src={author?.displayPic}
        style={{
          flexShrink: 0,
          backgroundColor: backgroundColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...style,
        }}
        className="fj-user-avatar"
        {...props}
      >
        <StyledFjText
          color={Colors.white}
          style={{
            fontSize: getFontSize(),
            lineHeight: '24px',
            letterSpacing: '0.32px',
            display: 'flex',
          }}
          className="fj-user-initials"
        >
          {userInitials}
        </StyledFjText>
      </Avatar>
    </Popover>
  )
}

const StyledFjText = styled(FjText)`
  // In FjTable component first-child font-weight is set to 600 so need to override it
  .ant-table-tbody > tr > td:first-child & {
    font-weight: 500 !important;
  }
`
