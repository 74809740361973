import React, { PropsWithChildren, ReactNode } from 'react'
import {
  FeedEmptyContent,
  FeedEmptyContentProps,
  FeedNoAccessContent,
  FeedNotFound,
} from 'src/components/Common/EmptyContent'
import { Loader, LoaderProps } from 'src/components/Common/Loader'

export type ViewState = 'initialLoad' | 'idle' | 'loading' | 'notFound' | 'noData' | 'error'

interface IViewWrapperProps extends PropsWithChildren {
  viewState: ViewState | string
  errorStateProps?: FeedEmptyContentProps
  noDataStateProps?: FeedEmptyContentProps
  loaderProps?: LoaderProps
  showLoader?: boolean
  customErrorUI?: ReactNode
  customNoDataUI?: ReactNode
}

export const ViewWrapper: React.FC<IViewWrapperProps> = ({
  viewState,
  errorStateProps = {},
  noDataStateProps = {},
  loaderProps = {},
  showLoader = true,
  customErrorUI,
  customNoDataUI,
  children,
}) => {
  if (viewState === 'initialLoad') return null
  if (viewState === 'error') {
    return <>{customErrorUI ? customErrorUI : <FeedNoAccessContent {...errorStateProps} />}</>
  }
  if (viewState === 'loading' && showLoader) {
    return <Loader {...loaderProps} />
  }
  if (viewState === 'notFound') {
    return <FeedNotFound />
  }
  if (viewState === 'noData') return <>{customNoDataUI || <FeedEmptyContent title="No data" {...noDataStateProps} />}</>
  return <>{children}</>
}
