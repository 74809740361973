import React, { Suspense, lazy, Component } from 'react'
import { FeedPage } from 'src/components/Page/Page'
import {
  ContainerDiv,
  PageTitle,
  FjFormItem,
  FjInput,
  FjText,
  FjMemoFormItem,
  Loader,
  DefaultButton,
  UploadThumbnailButton,
  GroupSelector,
  TagSelector,
  FeedEmptyContent,
  DropdownButton,
  FjCard,
  FjCheckBox,
  FjCheckboxProps,
  HubSelector,
} from 'src/components/Common'
import { Col, Dropdown, MenuProps, Row, Space, Tooltip } from 'antd'
import { Colors } from 'src/constants/colors'
import { Course } from 'src/models/Course'
import { observable, makeObservable, computed } from 'mobx'
import { observer } from 'mobx-react'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { combineValidations, isInteger, isMinValue, isRequired } from 'src/utils/validation'
import { getIDFromPath } from 'src/utils/urlParams'
import { SortableModuleList } from 'src/components/Sortable/SortableModuleList'
import { Submodule } from 'src/models/Submodule'
import { FormContainerModal } from 'src/components/Common/FormContainerModal'
import { FORM_CONTAINER_MODAL_ACTIONS } from 'src/components/Common/FormContainerModal'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { showNotification } from 'src/hoc/Notification'
import { Paths } from 'src/constants/navigation'
import { sharedDataStore } from 'src/store/DataStore'
import { RouteComponentProps } from 'react-router-dom'
import { getFeedContentTargetLink } from 'src/utils/content'
import { Hexagon, Columns, MoreHorizontal, Plus } from 'react-feather'
import { CustomMetadataFields } from 'src/components/Feed/CustomMetadataFields'
import { clearFalseyValues, deepEquals, formatDateToPST } from 'src/utils/format'
import { SfMetadataFormFields } from 'src/components/Feed/SfMetadataFormFields'
import { ExpirationDateField } from 'src/components/Feed/ExpirationDateField'
import { Module } from 'src/models/Module'
import { MAIN_CONTENT_COL_PROPS, SIDEBAR_COL_PROPS } from 'src/components/Page/ContentWrapper'
import { CertificateField } from 'src/components/Common/CertificateField'
import { Config } from 'src/models/Config'

const FJCKEditor = lazy(() => import('src/components/Common/FJCKEditor'))

interface ICourseBooleanFieldProps {
  tooltip?: string
  checkboxProps?: Omit<FjCheckboxProps, 'name'>
}

export const EnforceMediaCompletionField: React.FC<ICourseBooleanFieldProps> = ({
  tooltip = 'Prevent learners from completing modules unless all videos/audio have been watched/listened to.',
  checkboxProps = {},
}) => {
  return (
    <FjFormItem name="enforceMediaCompletion">
      <Tooltip title={tooltip}>
        &nbsp;
        <FjCheckBox name="enforceMediaCompletion" {...checkboxProps}>
          <FjText color={Colors.tapa}>Enforce Media Completion</FjText>
        </FjCheckBox>
      </Tooltip>
    </FjFormItem>
  )
}

export const RequireSequentialCompletionField: React.FC<ICourseBooleanFieldProps> = ({
  tooltip,
  checkboxProps = {},
}) => {
  return (
    <FjFormItem name="requireSequentialCompletion">
      {tooltip ? <Tooltip title={tooltip} /> : null}
      &nbsp;
      <FjCheckBox name="requireSequentialCompletion" {...checkboxProps}>
        <FjText color={Colors.tapa}>Require users to complete modules in sequential order</FjText>
      </FjCheckBox>
    </FjFormItem>
  )
}

type CourseAuthoringState = {
  modules: Module[]
  modulesLoading: boolean
}

@observer
export class CourseAuthoring extends Component<RouteComponentProps, CourseAuthoringState> {
  // We are using class state here because mobx state update doesn't trigger Formik rerendering.
  state: CourseAuthoringState = {
    modules: [],
    modulesLoading: false,
  }
  @observable course = new Course()
  @observable showModuleForm = false
  @observable showSubmoduleForm = false
  @observable saveDisabled = false
  isSubmoduleFormDirty = false

  actionModule?: Module
  actionSubmodule?: Submodule
  formikRef: any

  constructor(props) {
    super(props)
    makeObservable(this)
    this.formikRef = React.createRef()
  }

  componentDidMount() {
    this.initializeParamsAndFetch()
  }

  componentDidUpdate(prevProps: RouteComponentProps): void {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.initializeParamsAndFetch()
    }
  }

  initializeParamsAndFetch = async () => {
    // Initializing state to default
    this.course = new Course()
    this.setState(() => ({ modules: [] }))

    const courseId = getIDFromPath(1)
    if (courseId && courseId !== 'create') {
      try {
        this.setState(() => ({ modulesLoading: true }))
        this.course = await sharedAppStateStore.wrapAppLoading(
          Course.get(courseId, undefined, undefined, { expand: 'assignment,live_session,assignment.graders,hubs' })
        )
        this.setState(() => ({ modules: Module.fromData(this.course.modules) }))
      } catch (err) {
        sharedAppStateStore.handleError(err)
        sharedAppStateStore.navigate(Paths.getCoursePath({ isAuthoring: true }), true)
      } finally {
        this.setState(() => ({ modulesLoading: false }))
      }
    }
  }

  onPublish = async () => {
    const isValid = await this.validateForm(true)
    if (isValid) {
      sharedAppStateStore.sharedConfirmDialogProps = {
        onConfirm: () => this.onPublishConfirm(this.formikRef.current.values),
        content:
          'Are you sure you want to publish this course? This will make the course viewable by everyone in the selected groups.',
        confirmButtonTitle: 'Yes',
        cancelButtonTitle: 'No',
      }
    }
  }

  saveCourse = async (data: any) => {
    try {
      this.saveDisabled = true
      data['moduleData'] = this.state.modules.map((m) => m.formatForCourseSave())
      const {
        tags,
        groupIds,
        hubIds,
        sfMetadata,
        expiryDate,
        certificateId,
        customFields,
        recurringPeriod,
        estimatedDuration,
        ...newData
      } = data
      const shouldUpdateConfig = !deepEquals(this.course.customFields, customFields)

      await this.course.save(
        {
          ...newData,
          customFields,
          tags: tags || [],
          groupIds: groupIds || [],
          hubIds: hubIds || [],
          sfMetadata: clearFalseyValues(sfMetadata),
          expiryDate: formatDateToPST(expiryDate),
          certificateId: certificateId ?? '',
          recurringPeriod: recurringPeriod ? recurringPeriod : null,
          estimatedDuration: estimatedDuration ? estimatedDuration : null,
        },
        true,
        true
      )
      this.course = await sharedAppStateStore.wrapAppLoading(
        Course.get(this.course.id, undefined, undefined, { expand: 'assignment,live_session,assignment.graders,hubs' })
      )
      if (shouldUpdateConfig) await Config.refreshConfig()
      // After course save, response doesn't include modules information, so need to fill modules through get api.
      this.setState(() => ({ modules: Module.fromData(this.course.modules) }))
      sharedDataStore.localSettings.enforceMediaCompletion = data['enforceMediaCompletion']
      sharedDataStore.localSettings.requireSequentialCompletion = data['requireSequentialCompletion']
    } catch (err) {
      throw err
    } finally {
      this.saveDisabled = false
    }
  }

  onPublishConfirm = async (data: any) => {
    try {
      if (!this.course.publishedAt) {
        data['publishedAt'] = new Date().toISOString()
      }
      await sharedAppStateStore.wrapAppLoading(this.saveCourse({ ...data }))
      showNotification({
        message: `Your course has been successfully published!`,
        type: 'success',
        duration: 10,
        target: getFeedContentTargetLink(this.course),
        clickToView: true,
      })
      if (sharedDataStore.user.isFaasAdminOrManager()) {
        const hideAddToQueueModal = () => (sharedAppStateStore.addToQueueModalProps = undefined)

        sharedAppStateStore.addToQueueModalProps = undefined
        sharedAppStateStore.addToQueueModalProps = {
          onSuccess: hideAddToQueueModal,
          item: this.course,
          onCancel: hideAddToQueueModal,
        }
      }
      sharedAppStateStore.navigate(Paths.getCoursePath({ id: this.course.id }), true)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  onSave = async (data: any, preview = false) => {
    try {
      const isValid = await this.validateForm()
      if (!isValid) return
      await sharedAppStateStore.wrapAppLoading(this.saveCourse({ ...data }))
      showNotification({
        message: `Your course has been successfully saved!`,
        type: 'success',
        duration: 10,
        target: getFeedContentTargetLink(this.course),
        clickToView: true,
      })
      if (preview) window.open(getFeedContentTargetLink(this.course), '_blank')
      sharedAppStateStore.navigate(Paths.getCoursePath({ id: this.course.id, isAuthoring: true }), true)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  validateForm = async (isPublish: boolean = false) => {
    if ((isPublish || this.course.publishedAt) && !this.state.modules.length) {
      showNotification({ message: 'Please create at least 1 module', type: 'error' })
      return false
    }

    // Can't save without submodules even in draft because LearningSequence records don't make sense without submodule
    if (this.state.modules.map((m) => m.submodules.length).some((count) => !count)) {
      showNotification({ message: 'All sections must have at least 1 module', type: 'error' })
      return false
    }

    const errors = await this.formikRef.current.validateForm()
    if (Object.values(errors).length > 0) {
      this.formikRef.current.submitForm()
      return false
    }
    return true
  }

  getInitialValues = () => {
    let {
      name,
      description,
      tags,
      groupIds,
      hubIds,
      thumbnailUrl,
      customFields,
      sfMetadata,
      expiryDate,
      enforceMediaCompletion,
      requireSequentialCompletion,
      certificate,
      recurringPeriod,
      estimatedDuration,
    } = this.course

    if (!groupIds?.length && !sharedDataStore.user.isPartOfManyGroups()) {
      groupIds = [sharedDataStore.user.defaultGroup.id]
    }

    return {
      name,
      description,
      tags,
      groupIds,
      hubIds,
      thumbnailUrl,
      customFields,
      sfMetadata: {
        id: sfMetadata?.id,
        name: sfMetadata?.name,
        stageName: sfMetadata?.stageName,
      },
      expiryDate,
      enforceMediaCompletion: enforceMediaCompletion ?? sharedDataStore.localSettings.enforceMediaCompletion,
      requireSequentialCompletion:
        requireSequentialCompletion ?? sharedDataStore.localSettings.requireSequentialCompletion,
      certificateId: certificate?.id,
      recurringPeriod,
      estimatedDuration,
    }
  }

  updateModules = (modules: Module[]) => this.setState(() => ({ modules: modules }))

  closeModuleModal = () => {
    this.showModuleForm = false
    this.actionModule = undefined
  }

  closeSubmoduleModal = () => {
    if (this.isSubmoduleFormDirty) {
      sharedAppStateStore.sharedConfirmDialogProps = {
        onConfirm: this.handleCloseCreateModuleConfirmModal,
        title: 'Discard Module',
        content: 'Are you sure you want to discard your changes? You cannot undo this action.',
        confirmButtonTitle: 'Yes',
        cancelButtonTitle: 'No',
      }
    } else {
      this.showSubmoduleForm = false
      this.actionSubmodule = undefined
    }
  }

  setSubmoduleFormDirty = (isUpdated: boolean) => {
    if (this.showSubmoduleForm && isUpdated !== this.isSubmoduleFormDirty) {
      this.isSubmoduleFormDirty = isUpdated
    }
  }

  handleCloseCreateModuleConfirmModal = () => {
    this.showSubmoduleForm = false
    this.actionSubmodule = undefined
  }

  submoduleSaved = async (submodule: Submodule) => {
    const newModulesList = [...this.state.modules]
    const submodules = newModulesList.map((module) => module.submodules).flat()
    if (newModulesList.length === 0) {
      const newModule = new Module()
      await sharedAppStateStore.wrapAppLoading(newModule.save({ title: 'Section 1' }))
      newModule.submodules.push(submodule)
      newModulesList.push(newModule)
    } else if (submodules.findIndex((s) => s.id === submodule.id) === -1) {
      newModulesList[newModulesList.length - 1].submodules.push(submodule)
    } else {
      newModulesList.forEach((module) => {
        const index = module.submodules.findIndex((s) => s.id === submodule.id)
        if (index !== -1) module.submodules[index] = submodule
      })
    }
    this.setState(() => ({ modules: newModulesList }))
    this.isSubmoduleFormDirty = false
    this.closeSubmoduleModal()
  }

  itemClicked = (item: Module | Submodule) => {
    // TODO: if submoudle is an assignment, we need to save the course for providing the ability to edit that submodule again. so we are asking user to save course first before editing, it needs to be fixed for UX - Story ID : #186966055
    if (!this.course.id || !this.course.itemExistsInLearningSequence(item)) {
      showNotification({
        message: 'Please save the course before editing any existing sections or modules',
        type: 'warning',
      })
      return
    }
    if (item instanceof Module) {
      this.actionModule = item
      this.showModuleForm = true
    } else {
      this.actionSubmodule = item
      this.showSubmoduleForm = true
    }
  }

  handleItemDeleted = (item: Module | Submodule) => {
    if (item instanceof Module) {
      sharedAppStateStore.deleteDialogProps = {
        obj: item,
        onConfirm: (module) =>
          this.setState(({ modules }) => ({ modules: [...modules].filter((m) => m.id !== module.id) })),
      }
    } else {
      this.setState(({ modules }) => {
        return {
          modules: [...modules].map((module) => {
            module.submodules = module.submodules.filter((s) => s.id !== item.id)
            return module
          }),
        }
      })
    }
  }

  moduleSaved = (module: Module) => {
    if (!module) return
    this.setState(({ modules }) => {
      const newModules = [...modules]
      const index = newModules.findIndex((m) => m.id === module.id)
      if (index === -1) {
        newModules.push(module)
      } else {
        const oldModule = newModules[index]
        // have to keep the existing submodules in case user edited submodules without saving
        // then edits the title of section
        module.submodules = [...oldModule.submodules]
        newModules[index] = module
      }
      return { modules: newModules }
    })
    this.closeModuleModal()
  }

  handleDelete = async () => {
    try {
      await this.course.delete()
      sharedAppStateStore.navigate(Paths.getLibraryPath('course'))
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  showDeleteModal = () => {
    sharedAppStateStore.deleteDialogProps = {
      onConfirm: this.handleDelete,
      obj: this.course,
    }
  }

  @computed get menuItems() {
    return [
      {
        disabled:
          this.saveDisabled ||
          !(this.course?.author?.authorID === sharedDataStore.user.id || sharedDataStore.user.isFaasAdminOrManager()),
        label: 'Delete',
        onClick: this.showDeleteModal,
        key: 'delete',
      },
    ]
  }

  render() {
    const { course } = this
    const addMenuCotent: MenuProps = {
      items: [
        {
          key: 'addSection',
          label: 'Add Section',
          onClick: () => (this.showModuleForm = true),
          icon: <Columns size={20} />,
        },
        {
          key: 'addModule',
          label: 'Add Module',
          onClick: () => (this.showSubmoduleForm = true),
          icon: <Hexagon size={20} />,
        },
      ],
    }
    return (
      <FeedPage title={`${course.id ? 'Edit' : 'Create'} Course`}>
        <FormContainerModal
          open={this.showSubmoduleForm}
          handleClose={this.closeSubmoduleModal}
          action={FORM_CONTAINER_MODAL_ACTIONS.SubmoduleForm}
          formComponentProps={{
            onSave: this.submoduleSaved,
            submodule: this.actionSubmodule,
            submoduleFormDirty: this.setSubmoduleFormDirty,
          }}
          hide={!!sharedAppStateStore.videoModalProps}
        />
        <FormContainerModal
          open={this.showModuleForm}
          handleClose={this.closeModuleModal}
          action={FORM_CONTAINER_MODAL_ACTIONS.ModuleForm}
          formComponentProps={{ onSave: this.moduleSaved, module: this.actionModule }}
        />
        <Formik
          onSubmit={() => {}}
          initialValues={this.getInitialValues()}
          innerRef={this.formikRef}
          enableReinitialize
          isInitialValid
          validateOnMount
        >
          {({ isValid }) => {
            return (
              <Form>
                <Row justify="center" gutter={16}>
                  <Col {...MAIN_CONTENT_COL_PROPS}>
                    <FjCard>
                      <ContainerDiv display="flex" justifyContent="space-between" alignItems="center">
                        <PageTitle fontSize="26px" fontWeight="bold" textAlign="left" color={Colors.midnightBlue}>
                          {`${course.id ? 'Edit' : 'Create'} Course`}
                        </PageTitle>
                        <ContainerDiv display="flex" alignItems="center" justifyContent="space-between">
                          <Space>
                            <DefaultButton
                              buttonType="secondary"
                              title="Save"
                              clicked={() => this.onSave(this.formikRef.current.values)}
                              disabled={this.saveDisabled}
                            />
                            <DefaultButton
                              buttonType="tertiary"
                              title="View"
                              clicked={() => this.onSave(this.formikRef.current.values, true)}
                              disabled={this.saveDisabled || !isValid}
                            />
                            {!course.publishedAt ? (
                              <DefaultButton
                                buttonType="primary"
                                title="Publish"
                                clicked={this.onPublish}
                                disabled={this.saveDisabled || (!!course.id && !!course.publishedAt)}
                              />
                            ) : null}
                          </Space>
                          {this.course.id ? (
                            <Dropdown
                              menu={{
                                items: this.menuItems,
                              }}
                              placement="bottomLeft"
                            >
                              <MoreHorizontal
                                size={20}
                                color={Colors.cornflowerBlue}
                                style={{
                                  marginLeft: '5px',
                                  cursor: 'pointer',
                                }}
                              />
                            </Dropdown>
                          ) : null}
                        </ContainerDiv>
                      </ContainerDiv>
                      {course.publishedAt ? (
                        <ContainerDiv marginTop="0.5rem" textAlign="right">
                          <FjText>Published on {course.publishedAt.format('MM-DD-YYYY')}.</FjText>
                        </ContainerDiv>
                      ) : null}
                      <ContainerDiv marginTop="26px">
                        <FjFormItem name="name" fieldtitle="Title*" validate={isRequired}>
                          <FjInput placeholder="Title" name="name" />
                        </FjFormItem>
                      </ContainerDiv>
                      <FjMemoFormItem name="description" fieldtitle="Description" className="content-editor">
                        <Suspense fallback={<Loader />}>
                          <FJCKEditor
                            name="description"
                            placeholder="Write a brief summary about the course."
                            showRecordVideoBtn
                            includeMentions
                          />
                        </Suspense>
                      </FjMemoFormItem>
                      <SfMetadataFormFields
                        handleSelect={(_value: any, option: any) =>
                          this.formikRef.current.setFieldValue('sfMetadata', {
                            id: option.id,
                            name: option.name,
                            stageName: option.stageName,
                          })
                        }
                        handleClear={() => this.formikRef.current.setFieldValue('sfMetadata', null)}
                        expanded={!!this.course?.sfMetadata}
                      />
                      <Row gutter={16}>
                        {sharedDataStore.user.isPartOfManyGroups() ? (
                          <Col lg={12} md={24}>
                            <FjFormItem name="groupIds" fieldtitle="Groups*" validate={isRequired}>
                              <GroupSelector name="groupIds" />
                            </FjFormItem>
                          </Col>
                        ) : null}
                        <Col lg={12} md={24}>
                          <TagSelector />
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col lg={12} md={24}>
                          <ExpirationDateField />
                        </Col>
                        <Col lg={12} md={24}>
                          <CertificateField name="certificateId" initialLabel={this.course.certificate?.name} />
                        </Col>
                        <Col lg={12} md={24}>
                          <FjFormItem
                            name="recurringPeriod"
                            fieldtitle="Recurring Period"
                            tiptitle="Recurring Period is the number of days after which a user has to retake the course."
                            validate={combineValidations(isInteger, isMinValue(7))}
                          >
                            <FjInput name="recurringPeriod" suffix="days" placeholder="e.g. 365" />
                          </FjFormItem>
                        </Col>
                        <Col lg={12} md={24}>
                          <FjFormItem
                            name="estimatedDuration"
                            fieldtitle="Estimated Duration"
                            validate={combineValidations(isInteger, isMinValue(1))}
                          >
                            <FjInput name="estimatedDuration" suffix="minutes" placeholder="Number" />
                          </FjFormItem>
                        </Col>
                      </Row>
                      <EnforceMediaCompletionField />
                      <RequireSequentialCompletionField />
                      <CustomMetadataFields />
                      {sharedDataStore.user.isFaasAdmin() ? (
                        <FjFormItem name="hubIds" fieldtitle="Hubs">
                          <HubSelector name="hubIds" />
                        </FjFormItem>
                      ) : null}
                      <FjFormItem name="thumbnailUrl" fieldtitle="Thumbnail">
                        <UploadThumbnailButton name="thumbnailUrl" />
                      </FjFormItem>
                    </FjCard>
                  </Col>
                  <Col {...SIDEBAR_COL_PROPS}>
                    <FjCard padding="0" overflow="hidden">
                      <ContainerDiv
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="16px 24px"
                        borderBottom={`solid 1px ${Colors.sharkOpacity10}`}
                      >
                        <FjText
                          fontSize="20px"
                          fontWeight="bold"
                          color={Colors.midnightBlue}
                          display="block"
                          textAlign="left"
                        >
                          Modules
                        </FjText>
                        <DropdownButton menu={addMenuCotent} background={Colors.cornflowerBlue}>
                          <div style={{ display: 'flex', alignItems: 'center', color: Colors.white }}>
                            <Plus size={16} style={{ marginRight: '8px', pointerEvents: 'none', fontSize: '16px' }} />
                            Add
                          </div>
                        </DropdownButton>
                      </ContainerDiv>
                      {this.state.modulesLoading ? (
                        <ContainerDiv height="100px">
                          <Loader />
                        </ContainerDiv>
                      ) : this.state.modules.length ? (
                        <SortableModuleList
                          isAuthoring
                          modules={this.state.modules}
                          itemClicked={this.itemClicked}
                          updateModules={this.updateModules}
                          handleDelete={this.handleItemDeleted}
                        />
                      ) : (
                        <FeedEmptyContent title="Use the button above to add content" />
                      )}
                    </FjCard>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </FeedPage>
    )
  }
}
