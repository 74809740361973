import { formatPathWithId } from 'src/network/Route'
import { SettingsTabKey } from 'src/pages/Settings'
import { LibraryCategory } from 'src/pages/Library'
import { LearningContentType } from 'src/utils/content'
import { DashboardCategory, GroupBy } from 'src/pages/FeedDashboard'
import { ConsumptionChartContentType, ConsumptionChartType } from 'src/components/Dashboard/ConsumptionChart'
import { CreationTab } from 'src/components/Dashboard/CreationChart'
import { createQueryString } from 'src/utils/format'
import { ContentPerformanceAnalyticsTabKey } from 'src/components/Dashboard/ContentPerformanceAnalyticsView'
import { ContentTabsType, ProfileMenu } from 'src/components/Profile/Profile'

interface PathConfig<QueryParamsType> {
  id?: string
  isAuthoring?: boolean
  queryParams?: QueryParamsType
}

export const Paths = {
  // webflow routes
  home: '/',
  unsubscribe: '/unsubscribe',
  // public routes
  academy: '/academy/:urlPath?',
  // application routes
  login: '/login',
  register: '/register',
  linkedIn: '/linkedin',
  salesforce: '/salesforce',
  gong: '/gong',
  zoom: '/zoom',
  outreach: '/outreach',
  slack: '/slack',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  changePassword: '/change-password',
  profile: '/profile/:userid',
  settings: '/settings',
  dashboard: '/dashboard',
  activityDashboard: '/activity-dashboard',
  team: '/team',
  submodules: '/course/:courseId/submodule/:submoduleId?',
  gradeSubmissions: '/grades/submissions/:submissionId',
  submittedAssessments: '/submissions',
  feedPost: '/feed/post/:id',
  classroom: '/classroom',
  library: '/library',
  feed: '/feed',
  tagFeed: '/feed/tags/:tagName',
  createPlaylist: '/playlist/create',
  editPlaylist: '/playlist/:id/edit',
  createLearningPath: '/learningpath/create',
  editLearningPath: '/learningpath/:id/edit',
  viewLearningPath: '/learningpath/:id',
  createCourse: '/course/create',
  editCourse: '/course/:id/edit',
  viewCourse: '/course/:id',
  createHub: '/hub/create',
  editHub: '/hub/:id/edit',
  viewHub: '/hub/:id',
  createPage: '/page/create',
  editPage: '/page/:id/edit',
  viewPage: '/page/:id?',
  viewOpportunity: '/opportunity/:id',
  search: '/search',
  contentReview: '/feed/content-review',
  embed: '/embed',
  demoSlackMessage: '/demo-slack-message',
  automationRules: '/automation-rules',
  sharedContent: '/share',
  editCoachingMoment: '/coaching-moment/:id/edit',
  viewCoachingMoment: '/coaching-moment/:id',
  coachingMomentTrigger: '/coachingmoment-trigger',
  createDealRoom: '/dealroom/create',
  editDealRoom: '/dealroom/:id/edit',
  viewDealRoom: '/dealroom/:id',
  notFound: '*',
  getCreatePostPath: function (oppId?: string) {
    if (oppId) {
      return `/feed?create_post=true&opp_id=${oppId}`
    } else {
      return `/feed?create_post=true`
    }
  },
  getProfilePath: function (userid: string, params: { menu?: ProfileMenu; tab?: ContentTabsType } = {}) {
    return `/profile/${userid}${createQueryString(params)}`
  },
  getSettingsPath: function (tabKey: SettingsTabKey, filters: object = {}) {
    return `/settings/${createQueryString({ tab: tabKey, ...filters })}`
  },
  getApplicationPath: function (appID?: string, activePart?: number) {
    if (appID && activePart) return `/apply/${appID}/${activePart}`
    if (appID) return `/apply/${appID}`
    return '/apply'
  },
  getLinkedInOAuthRedirectURL: function () {
    return `${window.location.origin}/linkedin`
  },
  getSalesforceOAuthRedirectURL: function () {
    return `${window.location.origin}/salesforce`
  },
  getGongOAuthRedirectURL: function () {
    return `${window.location.origin}/gong`
  },
  getZoomOAuthRedirectURL: function () {
    return `${window.location.origin}/zoom`
  },
  getOutreachOAuthRedirectURL: function () {
    return `${window.location.origin}/outreach/`
  },
  getCandidateProfilePath: function (id: string) {
    const { path } = formatPathWithId('/profiles/', id)
    return path
  },
  getSubmissionPath: (submissionId: string) => {
    return `/grades/submissions/${submissionId}`
  },
  getClassroomPath: ({
    postId,
    playlistId,
    promptId,
    callId,
    assetId,
    queueItem,
    params = {},
  }: {
    postId?: string
    playlistId?: string
    promptId?: string
    callId?: string
    assetId?: string
    queueItem?: string
    params?: any
  }) => {
    return `/classroom/${createQueryString({
      playlistId,
      promptId,
      postId,
      callId,
      assetId,
      queueItem,
      ...params,
    })}`
  },

  getDashboardPath: ({
    category,
    tab,
    contentType,
    contentId,
    groupBy,
    authorId,
    startDate,
    endDate,
    createdAtAfter,
    createdAtBefore,
    groupId,
    hub_id,
    userId,
    managerId,
    course_id,
    assetId,
    viewType,
    week,
    tag,
    page,
    tableView,
  }: {
    category?: DashboardCategory
    tab?: CreationTab | ContentPerformanceAnalyticsTabKey
    contentType?: ConsumptionChartContentType
    contentId?: string
    groupBy?: GroupBy | ConsumptionChartType
    authorId?: string
    startDate?: string
    endDate?: string
    createdAtAfter?: string
    createdAtBefore?: string
    groupId?: string
    hub_id?: string
    userId?: string
    managerId?: string
    course_id?: string
    assetId?: string
    viewType?: string
    week?: string
    tag?: string
    page?: number
    tableView?: string
  }) => {
    const queryStr = createQueryString({
      category,
      tab,
      contentType,
      contentId,
      groupBy,
      authorId,
      startDate,
      endDate,
      createdAtAfter,
      createdAtBefore,
      groupId,
      hub_id,
      managerId,
      course_id,
      assetId,
      viewType,
      week,
      tag,
      page,
      tableView,
      userId,
    })
    return `/activity-dashboard/${queryStr}`
  },
  getSearchPath: (searchStr: string, contentType?: string, searchType?: string, params?: any) => {
    params = params || {}
    if (!params.contentType) params.contentType = contentType
    if (!params.q) params.q = searchStr
    if (!params.searchType) params.searchType = searchType
    return `${Paths.search}/${createQueryString(params)}`
  },
  getLoginPath: (params?: object) => {
    return `${Paths.login}${createQueryString({
      ...params,
    })}`
  },
  getLibraryPath: (contentType?: LibraryCategory, params?: any) => {
    params = params || {}
    if (!params.contentType) params.contentType = contentType
    return `/library/${createQueryString(params)}`
  },
  getTagFeedPath: (tag: string, contentType?: LearningContentType) =>
    `/feed/tags/${encodeURIComponent(tag)}${contentType ? `?contentType=${contentType}` : ''}`,
  getPlaylistAuthoringPath: (id?: string, redirectUri?: string) => {
    let path = `/playlist/${id ? `${id}/edit` : 'create'}`
    if (redirectUri) path = `${path}?redirectUri=${redirectUri}`
    return path
  },
  getPathwayPath: (id?: string) => {
    return id ? `/course/${id}/chapters` : 'course/'
  },
  getLearningPathPath: (params: PathConfig<{}>) => {
    const { id, isAuthoring, queryParams } = params || {}
    if (isAuthoring) return `/learningpath/${id ? `${id}/edit` : 'create'}`
    return `/learningpath/${id}${createQueryString(queryParams)}`
  },
  getCoursePath: (params: PathConfig<{}>) => {
    const { id, isAuthoring, queryParams } = params || {}
    if (isAuthoring) return `/course/${id ? `${id}/edit` : 'create'}`
    return `/course/${id}${createQueryString(queryParams)}`
  },
  getSharedCoursePath: (id: string) => {
    return `/course/${id}/shared`
  },
  getSubmodulePath: (courseId: string, submoduleId?: string) => {
    return submoduleId ? `/course/${courseId}/submodule/${submoduleId}/` : `/course/${courseId}/submodule/`
  },
  getHubPath: (params?: PathConfig<{}>) => {
    const { id, isAuthoring, queryParams } = params || {}
    if (isAuthoring) return `/hub/${id ? `${id}/edit` : 'create'}`
    return `/hub/${id}${createQueryString(queryParams)}`
  },
  getPagePath: (params?: PathConfig<{}>) => {
    const { id, isAuthoring } = params || {}
    if (isAuthoring) return `/page/${id ? `${id}/edit` : 'create'}`
    return `/page/${id ?? ''}`
  },
  getOpportunityPath: (params?: PathConfig<{ contentType?: string }>) => {
    const { id, queryParams } = params
    return `/opportunity/${id}${createQueryString(queryParams)}`
  },
  getCoachingMomentPath: (params?: PathConfig<{}>) => {
    const { id, isAuthoring } = params
    const path = `/coaching-moment/${id}`
    if (isAuthoring) return `${path}/edit`
    return path
  },
  getSharedContentPath: (id: string) => `/share?linkId=${id}`,
  getAcademyPath: (urlPath?: string, contentType?: LibraryCategory) => {
    const queryString = createQueryString({ contentType })
    if (urlPath) return `/academy/${urlPath}/${queryString}`
    return `/academy/${queryString}`
  },
  getDealRoomPath: (params: PathConfig<{ viewAnalytics?: boolean }>) => {
    const { id, isAuthoring, queryParams } = params || {}
    if (isAuthoring) return `/dealroom/${id ? `${id}/edit` : 'create'}`
    return `/dealroom/${id}${createQueryString(queryParams)}`
  },
  getEditAssetModalPath: (params?: PathConfig<{ editAssetId?: string; openDrive?: boolean }>) => {
    const { queryParams } = params || {}
    if (!queryParams.editAssetId) queryParams.editAssetId = 'new'
    return `/feed${createQueryString(queryParams)}`
  },
}

export const authRoutePaths = [
  Paths.forgotPassword,
  Paths.resetPassword,
  Paths.changePassword,
  Paths.salesforce,
  Paths.gong,
  Paths.zoom,
  Paths.slack,
  Paths.outreach,
  Paths.linkedIn,
  Paths.register,
]

export const baseRoutePaths = [Paths.home, Paths.unsubscribe, Paths.academy, Paths.library]

export const learningRoutesPaths = [Paths.submodules, Paths.gradeSubmissions, Paths.submittedAssessments]

export const feedRoutesPaths = [
  Paths.activityDashboard,
  Paths.feed,
  Paths.tagFeed,
  Paths.feedPost,
  Paths.classroom,
  Paths.settings,
  Paths.createPlaylist,
  Paths.editPlaylist,
  Paths.viewLearningPath,
  Paths.viewOpportunity,
  Paths.search,
  Paths.viewCourse,
  Paths.viewHub,
  Paths.viewPage,
  Paths.demoSlackMessage,
  Paths.profile,
  Paths.sharedContent,
  Paths.login,
  Paths.viewCoachingMoment,
  Paths.createDealRoom,
  Paths.editDealRoom,
  Paths.viewDealRoom,
]

export const adminManagerRoutesPaths = [
  Paths.createCourse,
  Paths.editCourse,
  Paths.createLearningPath,
  Paths.editLearningPath,
  Paths.createHub,
  Paths.editHub,
  Paths.createPage,
  Paths.editPage,
  Paths.team,
  Paths.contentReview,
  Paths.automationRules,
  Paths.editCoachingMoment,
  Paths.coachingMomentTrigger,
]
