import { Popover, PopoverProps } from 'antd'
import { Form, Formik } from 'formik'

import React, { useMemo, useState } from 'react'
import { ContainerDiv } from 'src/components/Common/Cards'
import { AutoCompleteOption } from 'src/components/Common/FjFilter'
import { FjCheckBox, FjInput } from 'src/components/Common/FjInput'

interface FjPopoverContentProps {
  options?: AutoCompleteOption[]
  values?: AutoCompleteOption[]
  onSearch?: (value: string) => void
  addFilterOption?: (option: AutoCompleteOption) => void
  removeFilterOption?: (option: AutoCompleteOption) => void
}

export const FjPopoverContent = ({
  options = [],
  values = [],
  onSearch,
  addFilterOption,
  removeFilterOption,
}: FjPopoverContentProps) => {
  const [searchKeyword, setSearchKeyword] = useState('')

  const filteredOptions = useMemo(() => {
    if (onSearch) return options
    return options.filter((option) => option.label.toLowerCase().includes(searchKeyword.toLowerCase()))
  }, [searchKeyword, onSearch, options])

  const handleSearchOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value)
    if (onSearch) onSearch(e.target.value)
  }

  const getInitialValues = () => {
    const initialValues = { searchKeyword }
    for (const option of filteredOptions) {
      initialValues[option.id] = !!values.find((value) => value.id === option.id)
    }
    return initialValues
  }

  return (
    <Formik initialValues={getInitialValues()} onSubmit={() => {}} enableReinitialize>
      <Form>
        <FjInput name="searchKeyword" size="small" placeholder="Search options" onChange={handleSearchOptions} />
        <ContainerDiv textAlign="left" marginTop="8px" maxHeight={350} overflow="auto" minWidth={200}>
          {filteredOptions.map((option) => (
            <ContainerDiv key={option.id} display="flex" alignItems="center" textAlign="left" gap={8} height="28px">
              <FjCheckBox
                size="small"
                name={option.id}
                onChange={(e) => (e.target.checked ? addFilterOption?.(option) : removeFilterOption?.(option))}
              >
                {option.label}
              </FjCheckBox>
            </ContainerDiv>
          ))}
        </ContainerDiv>
      </Form>
    </Formik>
  )
}

const FjPopover = ({ children, ...props }: PopoverProps) => {
  return (
    <Popover overlayClassName="menu-popover" showArrow={false} placement="bottomLeft" trigger={['click']} {...props}>
      {children}
    </Popover>
  )
}

export default FjPopover
