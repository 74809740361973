import moment from 'moment'
import { DataObject } from './DataObject'
import { sharedDataStore } from 'src/store/DataStore'

export class SessionToken extends DataObject {
  static OVERRIDE_MAPPINGS = {
    accessExpiry: (data) => (data.accessExpiry ? moment(data.accessExpiry) : undefined),
    refreshExpiry: (data) => (data.refreshExpiry ? moment(data.refreshExpiry) : undefined),
  }

  static isRefreshTokenExpired = () => {
    const { sessionToken } = sharedDataStore.user
    if (!sessionToken.refreshToken || !sessionToken.refreshExpiry) return true
    return sessionToken.refreshExpiry.isBefore(moment())
  }

  static isAccessTokenExpired = () => {
    const { sessionToken } = sharedDataStore.user
    if (!sessionToken.accessToken || !sessionToken.accessExpiry) return true
    return sessionToken.accessExpiry.isBefore(moment().add(1, 'minutes'))
  }

  accessToken: string
  refreshToken: string
  accessExpiry: moment.Moment
  refreshExpiry: moment.Moment

  constructor(accessToken: string, refreshToken: string, accessExpiry: moment.Moment, refreshExpiry: moment.Moment) {
    super()
    this.accessToken = accessToken
    this.refreshToken = refreshToken
    this.accessExpiry = accessExpiry
    this.refreshExpiry = refreshExpiry
  }
}
