import moment from 'moment'
import humps from 'humps'
import { observable, makeObservable, computed } from 'mobx'
import { Favouritable } from 'src/models/Favouritable'
import { Author } from 'src/models/Author'
import { sharedDataStore } from 'src/store/DataStore'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { FeedComment } from 'src/models/FeedComment'
import { CallbackDoc } from 'react-google-drive-picker/dist/typeDefs'
import { FlockjayProvider } from 'src/network/FlockjayProvider'
import Route from 'src/network/Route'
import MethodTypes from 'src/models/enums/MethodTypes'
import { uniqueS3Filename } from 'src/utils/renameFile'
import { ReviewStatus } from 'src/models/FeedPost'
import { Page } from 'src/models/Page'
import { AppearsInType, LearningContentType } from 'src/utils/content'
import { Colors } from 'src/constants/colors'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Paths } from 'src/constants/navigation'
import { showNotification } from 'src/hoc/Notification'
import { titleCase } from 'src/utils/format'
import { MiniHub } from 'src/models/Hub'

const MS_OFFICE_MIME_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-excel',
  'application/msword',
]

export class Asset extends Favouritable {
  static OVERRIDE_MAPPINGS = {
    key: (data) => data.id,
    author: (data) => (data.author ? Author.fromData(data.author) : undefined),
    latestComments: (data) => (data.latestComments ? data.latestComments.map((c) => FeedComment.fromData(c)) : []),
    sfMetadata: ({ sfMetadata }) =>
      Object.keys(sfMetadata ?? {}).length > 0 ? SfOpportunity.fromData(sfMetadata) : undefined,
    groupIds: (data) => (data.groupIds ? data.groupIds : []),
    hubs: (data) => (data.hubs ? data.hubs : []),
    tags: (data) => (data.tags ? data.tags : []),
    customFields: (data) => (data.customFields ? humps.decamelizeKeys(data.customFields) : {}),
    page: (data) => (data.page ? Page.fromData(data.page) : undefined),
    expiryDate: (data) => (data.expiryDate ? moment.tz(data.expiryDate, 'America/Los_Angeles') : null),
    appearsIn: (data) => data.appearsIn ?? [],
    learningContentType: () => 'asset',
  }
  static apiEndpoint: string = '/feed/assets/'
  static dateDisplayFormat = 'M/d/YY h:mma'
  static shouldUseCache: boolean = true

  learningContentType: LearningContentType = 'asset'

  getBulkEditFields = () => [
    'groupIds',
    'tags',
    'hubs',
    'preventDownload',
    'preventDuplicate',
    'isInternal',
    'sfMetadata',
    'expiryDate',
    'customFields',
  ]

  key: string
  id: string
  title: string
  description: string
  content: string
  @observable fileUrl: string
  linkedResourceUrl: string
  embedHtml: string
  fileType: string
  contentType: string
  @observable thumbnailUrl: string
  @observable isFavourited = false
  @observable expiryDate: moment.Moment
  author: Author
  @observable commentCount: number = 0
  @observable likeCount: number = 0
  @observable liked: boolean = false
  @observable latestComments: FeedComment[]
  status: ReviewStatus = 'accepted'
  progress: number
  tags: string[] = []
  groupIds: string[] = []
  hubs: MiniHub[] = []
  createdAt: moment.Moment
  lastUpdated: moment.Moment
  sfMetadata?: SfOpportunity
  // TODO: this field should be called metadata but haven't
  // had the chance to rename it yet
  @observable googleDriveMetadata?: any
  urlPreview?: {
    url: string
    title: string
    imageSource: string
    description: string
  }
  customFields: object
  viewCount: number
  downloadCount: number
  page?: Page
  isInternal: boolean
  preventDownload: boolean
  preventDuplicate: boolean
  appearsIn: AppearsInType[]
  summary?: string
  learningContentId: string
  templateId?: string
  analytics?: {
    totalDownloadCount: number
    totalLinkCount: number
    totalViews: number
  }

  @computed get hubIds() {
    return this.hubs.map((hub) => hub.id)
  }

  constructor() {
    super()
    makeObservable(this)
  }

  static transferDriveDocToS3 = async (
    doc: CallbackDoc
  ): Promise<{ fileUrl: string; contentType: string; filename: string }> => {
    const { data } = await FlockjayProvider(
      new Route(MethodTypes.POST, `${this.apiEndpoint}download_google_doc/`, {
        fileId: doc.id,
        contentType: doc.mimeType,
        filename: uniqueS3Filename(doc.name),
      })
    )
    return data
  }

  static getEngagementOverview = async (assetId: string, queryParams?: any) => {
    const { path } = Asset.formatActionEndpoint(assetId, undefined, 'engagement_overview')
    return await FlockjayProvider(new Route(MethodTypes.GET, path, {}, {}, queryParams))
  }

  static getViewsSummaryByUser = async (assetId: string, queryParams?: any) => {
    const { path } = Asset.formatActionEndpoint(assetId, undefined, 'views_summary_by_user')
    return await FlockjayProvider(new Route(MethodTypes.GET, path, {}, {}, queryParams))
  }

  static getInternalAssetDashboardData = async (queryParams?: any, headers?: object) => {
    const { path } = Asset.formatActionEndpoint(undefined, undefined, 'internal_dashboard')
    return await FlockjayProvider(new Route(MethodTypes.GET, path, {}, headers, queryParams, undefined, { retries: 0 }))
  }

  duplicate = async (loadingMsg?: string, toastMsg?: string) => {
    try {
      if (
        this.hasGoogleDriveMetadata() &&
        this.googleDriveMetadata['id'] &&
        !sharedDataStore.user.googleDriveAccessToken
      ) {
        sharedAppStateStore.handleError(
          new Error('Please connect your Google account before attempting to duplicate this asset')
        )
        return
      }
      const data = await sharedAppStateStore.wrapAppLoading(
        this.post('duplicate', undefined, false),
        loadingMsg || 'Duplicating...'
      )
      const newAsset = Asset.fromData(data)
      showNotification({ message: toastMsg || `${this.title} was successfully duplicated!` })
      sharedAppStateStore.navigate(Paths.getClassroomPath({ assetId: newAsset.id }))
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  getLastEditedUtc = () => {
    if (this.googleDriveMetadata['lastEditedUtc']) {
      return moment.unix(this.googleDriveMetadata['lastEditedUtc'])
    }
  }

  shouldShowDrivePreview = () => {
    return (
      this.hasGoogleDriveMetadata() &&
      ['application/vnd.google-apps.document', 'application/vnd.google-apps.spreadsheet'].includes(
        this.googleDriveMetadata['mimeType']
      )
    )
  }

  getEmbedableUrl = () => {
    if (this.hasGoogleDriveMetadata()) return this.googleDriveMetadata['embedUrl']
  }

  hasGoogleDriveMetadata = () => !!Object.keys(this.googleDriveMetadata || {}).length

  canOpenGoogleDriveFile = () =>
    this.hasGoogleDriveMetadata() && this.googleDriveMetadata['url']?.includes('google.com')

  canEditGoogleDriveFile = () => {
    return (
      this.hasGoogleDriveMetadata() &&
      [
        'application/vnd.google-apps.document',
        'application/vnd.google-apps.spreadsheet',
        'application/vnd.google-apps.presentation',
      ].includes(this.googleDriveMetadata['mimeType'])
    )
  }

  canResyncFromDrive = () => this.hasGoogleDriveMetadata() && this.googleDriveMetadata['lastEditedUtc']

  resyncFromDrive = async (delayMs: number = 0) => {
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        try {
          const lastEditedUtc = this.getLastEditedUtc()
          await this.post('drive_sync', undefined, true)
          resolve(this.getLastEditedUtc() !== lastEditedUtc)
        } catch (err) {
          reject(err)
        }
      }, delayMs)
    })
  }

  driveEditUrl = () => {
    if (!this.googleDriveMetadata && !this.googleDriveMetadata['url']) return null
    const urlFileTypeParam = this.fileType === 'spreadsheet' ? 'spreadsheets' : this.fileType
    return (this.googleDriveMetadata['url'] as string)
      .replace('drive.google.com', 'docs.google.com')
      .replace('file/d/', `${urlFileTypeParam}/d/`)
      .replace('/view', '/edit')
  }

  frontendQueryParamKey = () => 'assetId'

  getDisplayObjectName = () => 'asset'

  likeButtonClicked = async () => {
    const responseData = await this.post('react', { reaction_type: 'like' })
    const updatedAsset: Asset = Asset.fromData(responseData)
    this.liked = updatedAsset.liked
    this.likeCount = updatedAsset.likeCount
  }

  likeCountDisplay = () => {
    if (this.likeCount === 0) return ''
    return `${this.likeCount}`
  }

  updateLatestCommentsIfExists = (comment: FeedComment) => {
    const comments = [...this.latestComments]
    const index = this.latestComments.findIndex((c) => c.id === comment.id)
    if (index !== -1) {
      comments[index] = comment
      this.latestComments = comments
    }
  }

  static getFilename = (fileUrl: string) => {
    const elements = fileUrl.split('/')
    return elements[elements.length - 1]
  }

  static cleanFileName = (fileUrl: string) => {
    const splitFile = this.getFilename(fileUrl).split('.')
    splitFile.pop()
    return splitFile.join('.')
  }

  canEdit = () =>
    this.author ? sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdmin() : false

  isExpired = () => {
    if (this.expiryDate) return this.expiryDate < moment()
    return false
  }

  canArchive = () => this.canEdit() && !this.isExpired()

  isExpiringSoon = () => this.canArchive() && this.expiryDate?.diff(moment(), 'days') <= 7

  canOpenAnalyticsModal = () => {
    if (!this.author || !this.id) return false
    return sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdminOrManager()
  }

  canShareExternally = () => {
    if (!this.author || !this.id) return false
    return !this.isInternal
  }

  canDelete = () => this.canEdit()

  canAddToCollection = () => true

  canAddToLearningPath = () => sharedDataStore.user.isFaasAdminOrManagerOrPartner()

  canAddToHub = () => sharedDataStore.user.isFaasAdminOrPartner()

  canAddToPromotedSearch = () => sharedDataStore.user.isFaasAdmin()

  canDuplicate = () => this.id && this.author?.authorID && !this.preventDuplicate

  isImage = () => this.contentType.includes('image')

  isVideo = () => this.contentType.includes('video')

  isPDF = () => this.contentType.includes('pdf')

  isMSOffice = () => {
    return MS_OFFICE_MIME_TYPES.some((substr) => this.contentType.includes(substr))
  }

  isKeynote = () => this.fileUrl.endsWith('.key')

  getTrackingElementId = () => `assetelement-${this.id}`

  getViewTrackingEventType = () => 'asset_viewed'

  getViewTrackingObjIdKey = () => 'asset_id'

  viewTrackingThresholdReached = (start: moment.Moment) => true

  isAccepted = () => this.status === 'accepted'

  isInReview = () => this.status === 'in_review'

  isRejected = () => this.status === 'rejected'

  getHeroBgColor = () => Colors.frenchPass2
  getContentTypeTagColor = () => Colors.malibu
  getTagBgColor = () => Colors.solitude
  getTagTitle = (config?: { includeFileType?: boolean; showTemplate?: boolean }) => {
    const { includeFileType, showTemplate } = config || {}
    let tagTitle = 'Asset'
    if (includeFileType && this.fileType) {
      tagTitle = `${tagTitle} - ${this.fileTypeDisplay()}`
    } else if (!!this.templateId && showTemplate) {
      tagTitle = `${tagTitle} - Template`
    }
    return tagTitle
  }

  imgSrc = () => {
    if (this.thumbnailUrl) return this.thumbnailUrl
    if (this.isImage()) return this.fileUrl
    else if (this.urlPreview?.imageSource) return this.urlPreview.imageSource
    return null
  }

  hasOpportunity = () => !!this.sfMetadata?.id

  hasStageName = () => !!this.sfMetadata?.stageName

  hasCustomFields = () => !!Object.keys(this.customFields).length

  canCreateTemplate = () =>
    !!this.id && !!this.author?.authorID && !this.templateId && sharedDataStore.user.isFaasAdminOrManager()

  fileTypeDisplay = () => (this.fileType === 'pdf' ? this.fileType.toUpperCase() : titleCase(this.fileType))
}
