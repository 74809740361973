import React from 'react'
import {
  HelpCircle,
  Layers,
  TrendingUp,
  BookOpen,
  Box,
  List,
  Target,
  Folder,
  File,
  PlayCircle,
  Briefcase,
  Zap,
  Phone,
} from 'react-feather'
import SalesforceBlueIcon from 'src/assets/icons/SalesforceBlue.svg'
import SalesforceBlueThumbnailIcon from 'src/assets/icons/SalesforceBlueThumbnail.svg'
import GongIcon from 'src/assets/icons/gong.svg'
import ClariIcon from 'src/assets/icons/clari.svg'
import GongThumbnailIcon from 'src/assets/icons/gongThumbnail.svg'
import ChorusIcon from 'src/assets/icons/chorus.svg'
import ChorusThumbnailIcon from 'src/assets/icons/chorusThumbnail.svg'
import { LearningPathElement, LearningPath, LearningPathElementType } from 'src/models/LearningPath'
import { Course } from 'src/models/Course'
import { FeedPost } from 'src/models/FeedPost'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { Prompt } from 'src/models/Prompt'
import { FeedTask } from 'src/models/FeedTask'
import { Paths } from 'src/constants/navigation'
import { APIProvider } from 'src/network/APIProvider'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { Call } from 'src/models/Call'
import { LibraryCategory } from 'src/pages/Library'
import { Asset } from 'src/models/Asset'
import { sharedDataStore } from 'src/store/DataStore'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { ConsumptionChartContentType } from 'src/components/Dashboard/ConsumptionChart'
import { Hub } from 'src/models/Hub'
import { Page } from 'src/models/Page'
import { AutomationRule } from 'src/models/AutomationRule'
import { Group } from 'src/models/Group'
import { Rubric } from 'src/models/Rubric'
import { CoachingMomentTrigger } from 'src/models/CoachingMomentTrigger'
import { DeleteableElement } from 'src/utils/getMenuContext'
import { Module } from 'src/models/Module'
import moment from 'moment'
import { pluralize } from 'src/utils/format'
import { DealRoom } from 'src/models/DealRoom'
import humps from 'humps'
import { clearFalseyValues } from 'src/utils/format'
import { handleCSVExportResponse } from 'src/utils/fileOperation'
import { Template } from 'src/models/Template'
import { User } from 'src/models/User'
import { ProgressAggregateDataType } from 'src/components/Learn/GradebookTable'
import { UserCertificate } from 'src/models/UserCertificate'
import { FlockjayProvider } from 'src/network/FlockjayProvider'
import Route from 'src/network/Route'
import MethodTypes from 'src/models/enums/MethodTypes'
import { AxiosResponse } from 'axios'

export const CONFETTI_LOCALSTORAGE_KEY = 'confettiOpenedStatus'

export type LearningContentType =
  | LearningPathElementType
  | 'learningpath'
  | 'opportunity'
  | 'coachingmoment'
  | 'hub'
  | 'page'
  | 'dealroom'

export type LearningContent =
  | FeedPost
  | Course
  | FeedPlaylist
  | Prompt
  | Call
  | SfOpportunity
  | LearningPath
  | Asset
  | Page
  | DealRoom

export type AppearsInType = {
  id: string
  contentType: LearningContentType
  title: string
}

export interface UserCompletion {
  dueDate: moment.Moment
  user: User
  progress: number
}

export interface PathConfig {
  isQueue?: boolean
  queueItemToHighlight?: string
  promptId?: string
  playlistId?: string
  isAuthoring?: boolean
}

export const getFeedContentType = (
  obj: LearningContent | FeedTask | CoachingMoment | Hub | Page
): LearningContentType => {
  if (obj instanceof Course) return 'course'
  if (obj instanceof FeedPost) return 'feedpost'
  if (obj instanceof FeedPlaylist) return 'playlist'
  if (obj instanceof Prompt) return 'prompt'
  if (obj instanceof FeedTask) return 'task'
  if (obj instanceof LearningPath) return 'learningpath'
  if (obj instanceof Call) return 'call'
  if (obj instanceof SfOpportunity) return 'opportunity'
  if (obj instanceof Asset) return 'asset'
  if (obj instanceof CoachingMoment) return 'coachingmoment'
  if (obj instanceof Hub) return 'hub'
  if (obj instanceof Page) return 'page'
  if (obj instanceof DealRoom) return 'dealroom'
}

export const getFeedContentClass = (contentType: LearningContentType) => {
  switch (contentType) {
    case 'course':
      return Course
    case 'feedpost':
      return FeedPost
    case 'playlist':
      return FeedPlaylist
    case 'prompt':
      return Prompt
    case 'learningpath':
      return LearningPath
    case 'opportunity':
      return SfOpportunity
    case 'call':
      return Call
    case 'asset':
      return Asset
    case 'task':
      return FeedTask
    case 'hub':
      return Hub
    case 'coachingmoment':
      return CoachingMoment
    case 'dealroom':
      return DealRoom
    case 'page':
      return Page
  }
}

export const getFeedContentTypeName = ({
  obj,
  contentType,
  upperCase = false,
}: {
  obj?: LearningContent | FeedTask | CoachingMoment | Hub | Page
  contentType?: LibraryCategory | LearningContentType
  upperCase?: boolean
}) => {
  let targetContentType = contentType
  if (obj) targetContentType = getFeedContentType(obj)
  const title = getContentCategoryTitle(targetContentType)
  return upperCase ? title.slice(0, -1) : title.slice(0, -1).toLowerCase()
}

export const getContentCategoryTitle = (contentType: LibraryCategory | LearningContentType | 'draft') => {
  switch (contentType) {
    case 'top':
      return `Popular @ ${sharedDataStore.user.company.name}`
    case 'managerPicks':
      return `Manager's Picks`
    case 'favourites':
      return 'Favorites'
    case 'course':
      return 'Courses'
    case 'feedpost':
      return 'Posts'
    case 'playlist':
      return 'Collections'
    case 'prompt':
      return 'Prompts'
    case 'learningpath':
      return 'Learning Paths'
    case 'opportunity':
      return 'Opportunities'
    case 'call':
      return 'Calls'
    case 'asset':
      return 'Assets'
    case 'task':
      return 'Tasks'
    case 'hub':
      return 'Hubs'
    case 'coachingmoment':
      return 'Coaching Moments'
    case 'dealroom':
      return 'Deal Rooms'
    case 'page':
      return 'Pages'
    case 'draft':
      return 'Drafts'
  }
}

export const getFeedContentTitle = (obj: LearningContent | Hub | FeedTask | Page | CoachingMoment) => {
  if (obj instanceof Course) return obj.name
  if (obj instanceof FeedPost) return obj.title
  if (obj instanceof FeedPlaylist) return obj.name
  if (obj instanceof Prompt) return obj.question
  if (obj instanceof FeedTask) return obj.title
  if (obj instanceof LearningPath) return obj.title
  if (obj instanceof SfOpportunity) return obj.opportunityName
  if (obj instanceof Call) return obj.title
  if (obj instanceof Asset) return obj.title
  if (obj instanceof Hub) return obj.name
  if (obj instanceof Page) return obj.title
  if (obj instanceof CoachingMoment) return obj.title
  if (obj instanceof DealRoom) return obj.title
  return ''
}

export const ContentTypeIcon = ({
  obj,
  contentType,
  thumbnail,
  ...props
}: {
  obj?: LearningContent | FeedTask | CoachingMoment | Hub | Page
  contentType?: LibraryCategory | LearningContentType
  size?: number
  color?: string
  thumbnail?: boolean
}) => {
  let targetContentType = contentType
  if (obj) targetContentType = getFeedContentType(obj)

  const defaultStyle = { minWidth: props.size ?? 24 }
  if (targetContentType === 'course') return <BookOpen style={defaultStyle} {...props} />
  if (targetContentType === 'feedpost') return <PlayCircle style={defaultStyle} {...props} />
  if (targetContentType === 'playlist') return <Layers style={defaultStyle} {...props} />
  if (targetContentType === 'prompt') return <HelpCircle style={defaultStyle} {...props} />
  if (targetContentType === 'task') return <List style={defaultStyle} {...props} />
  if (targetContentType === 'learningpath') return <TrendingUp style={defaultStyle} {...props} />
  if (targetContentType === 'call') {
    if (obj instanceof Call && obj.source === 'chorus') {
      return (
        <img
          style={defaultStyle}
          height={props.size}
          src={thumbnail ? ChorusThumbnailIcon : ChorusIcon}
          alt="Chorus logo"
          {...props}
        />
      )
    } else if (obj instanceof Call && obj.source === 'gong') {
      return (
        <img
          style={defaultStyle}
          height={props.size}
          src={thumbnail ? GongThumbnailIcon : GongIcon}
          alt="Gong logo"
          {...props}
        />
      )
    } else if (obj instanceof Call && obj.source === 'clari') {
      return (
        <img
          style={{ ...defaultStyle, marginTop: '-2px' }}
          height={props.size}
          src={thumbnail ? ClariIcon : ClariIcon}
          alt="Clari logo"
          {...props}
        />
      )
    }
    return <Phone style={defaultStyle} {...props} />
  }
  if (targetContentType === 'opportunity') {
    return (
      <img
        alt=""
        src={thumbnail ? SalesforceBlueThumbnailIcon : SalesforceBlueIcon}
        style={{ ...defaultStyle, width: props.size }}
        {...props}
      />
    )
  }
  if (targetContentType === 'asset') return <Box style={defaultStyle} {...props} />
  if (targetContentType === 'coachingmoment') return <Target style={defaultStyle} {...props} />
  if (targetContentType === 'hub') return <Folder style={defaultStyle} {...props} />
  if (targetContentType === 'page') return <File style={defaultStyle} {...props} />
  if (targetContentType === 'dealroom') return <Briefcase style={defaultStyle} {...props} />
  if (targetContentType === 'managerPicks') return <Zap style={defaultStyle} {...props} />
}

export const getFeedContentBody = (
  obj: LearningPathElement | Page | Hub | CoachingMoment | SfOpportunity | DealRoom
): string => {
  if (obj instanceof FeedPost) return obj.body
  if (obj instanceof Page || obj instanceof DealRoom) return obj.content
  if (
    obj instanceof Course ||
    obj instanceof FeedPlaylist ||
    obj instanceof Prompt ||
    obj instanceof Asset ||
    obj instanceof Hub ||
    obj instanceof LearningPath ||
    obj instanceof SfOpportunity
  )
    return obj.description
  return ''
}

export const getFeedContentData = (data: any, contentType: LearningContentType | 'page') => {
  switch (contentType) {
    case 'course':
      return Course.fromData(data)
    case 'feedpost':
      return FeedPost.fromData(data)
    case 'playlist':
      return FeedPlaylist.fromData(data)
    case 'prompt':
      return Prompt.fromData(data)
    case 'learningpath':
      return LearningPath.fromData(data)
    case 'task':
      return FeedTask.fromData(data)
    case 'opportunity':
      return SfOpportunity.fromData(data)
    case 'call':
      return Call.fromData(data)
    case 'asset':
      return Asset.fromData(data)
    case 'page':
      return Page.fromData(data)
    case 'hub':
      return Hub.fromData(data)
    case 'coachingmoment':
      return CoachingMoment.fromData(data)
    case 'dealroom':
      return DealRoom.fromData(data)
    default:
      return data
  }
}

export const getFeedContentTargetLink = (
  obj: LearningContent | Hub | Page | AutomationRule | CoachingMoment | CoachingMomentTrigger,
  config?: PathConfig
) => {
  let { isQueue, queueItemToHighlight, promptId, playlistId, isAuthoring } = config || {}

  let queueItem = null
  if (queueItemToHighlight) queueItem = queueItemToHighlight
  else if (isQueue) queueItem = obj.id

  let targetObj = obj instanceof FeedPost && obj.linkedObject ? obj.linkedObject : obj

  if (!promptId && obj instanceof FeedPost && obj.prompt) {
    promptId = obj.prompt.id
  }

  if (targetObj instanceof FeedPost)
    return Paths.getClassroomPath({
      postId: targetObj.id,
      promptId,
      playlistId,
      queueItem,
    })
  else if (targetObj instanceof Course) {
    return Paths.getCoursePath({ id: targetObj.id, isAuthoring })
  } else if (targetObj instanceof FeedPlaylist) {
    if (isAuthoring) {
      const redirectPath = window.location.pathname.includes('/library') ? Paths.library : undefined
      return Paths.getPlaylistAuthoringPath(targetObj.id, redirectPath)
    }
    return Paths.getClassroomPath({ playlistId: targetObj.id, queueItem })
  } else if (targetObj instanceof LearningPath) {
    return Paths.getLearningPathPath({ id: targetObj.id, isAuthoring })
  } else if (targetObj instanceof SfOpportunity) {
    return Paths.getOpportunityPath({ id: targetObj.id })
  } else if (targetObj instanceof Call) {
    return Paths.getClassroomPath({ callId: targetObj.id, playlistId: playlistId, queueItem })
  } else if (targetObj instanceof Asset) {
    return Paths.getClassroomPath({ assetId: targetObj.id, playlistId: playlistId, queueItem })
  } else if (targetObj instanceof Prompt) {
    return Paths.getClassroomPath({ promptId: targetObj.id, queueItem })
  } else if (targetObj instanceof Hub) {
    return Paths.getHubPath({ id: targetObj.id, isAuthoring })
  } else if (targetObj instanceof Page) {
    return Paths.getPagePath({ id: targetObj.id, isAuthoring })
  } else if (targetObj instanceof AutomationRule) {
    return Paths.automationRules
  } else if (targetObj instanceof CoachingMoment) {
    return Paths.getCoachingMomentPath({ id: targetObj.id, isAuthoring })
  } else if (targetObj instanceof CoachingMomentTrigger) {
    return Paths.coachingMomentTrigger
  } else if (targetObj instanceof DealRoom) {
    return Paths.getDealRoomPath({ id: targetObj.id, isAuthoring })
  } else {
    return ''
  }
}

export const fetchContentById = async (
  contentId: string,
  contentType: ConsumptionChartContentType | 'hub' | 'page',
  queryParams: object = {},
  abortController?: AbortController
) => {
  switch (contentType) {
    case 'feedpost':
      return await FeedPost.get(contentId, undefined, undefined, queryParams, abortController)
    case 'playlist':
      return await FeedPlaylist.get(contentId, undefined, undefined, queryParams, abortController)
    case 'prompt':
      return await Prompt.get(contentId, undefined, undefined, queryParams, abortController)
    case 'course':
      return await Course.get(contentId, undefined, undefined, queryParams, abortController)
    case 'learningpath':
      return await LearningPath.get(contentId, undefined, undefined, queryParams, abortController)
    case 'asset':
      return await Asset.get(contentId, undefined, undefined, queryParams, abortController)
    case 'call':
      return await Call.get(contentId, undefined, undefined, queryParams, abortController)
    case 'opportunity':
      return await SfOpportunity.get(contentId, undefined, undefined, queryParams, abortController)
    case 'task':
      return await FeedTask.get(contentId, undefined, undefined, queryParams, abortController)
    case 'hub':
      return await Hub.get(contentId, undefined, undefined, queryParams, abortController)
    case 'page':
      return await Page.get(contentId, undefined, undefined, queryParams, abortController)
    case 'dealroom':
      return await DealRoom.get(contentId, undefined, undefined, queryParams, abortController)
  }
}

export const fetchContent = async (
  contentType: LibraryCategory | LearningContentType | 'draft',
  queryParams: object = {},
  customEndpoint?: string,
  abortController?: AbortController,
  useCache?: boolean
) => {
  let newData: any[] = []
  let hasNextPage = false
  let total = 0
  let newCustomFields: object = undefined
  switch (contentType) {
    case 'top': {
      newData = await FeedPost.list(queryParams, undefined, 'popular', undefined, abortController)
      hasNextPage = false
      break
    }
    case 'managerPicks': {
      const { results, next, count } = await APIProvider.getFavourites(queryParams, abortController)
      newData = results
      hasNextPage = !!next
      total = count
      break
    }
    case 'favourites': {
      const { results, next, count } = await APIProvider.getFavourites(queryParams, abortController)
      newData = results
      hasNextPage = !!next
      total = count
      break
    }
    case 'fullsearch': {
      const { results, next, count, customFields } = await APIProvider.getFullSearch(queryParams, abortController)
      newData = results
      hasNextPage = !!next
      total = count
      newCustomFields = customFields
      break
    }
    case 'aisearch': {
      const { results, next, count, customFields } = await APIProvider.getAISearch(queryParams, abortController)
      newData = results
      hasNextPage = !!next
      total = count
      newCustomFields = customFields
      break
    }
    case 'feedpost': {
      const { data, next, count } = await FeedPost.list(
        queryParams,
        undefined,
        undefined,
        customEndpoint,
        abortController,
        useCache
      )
      newData = data
      hasNextPage = !!next
      total = count
      break
    }
    case 'playlist': {
      const { data, next, count } = await FeedPlaylist.list(
        queryParams,
        undefined,
        undefined,
        customEndpoint,
        abortController,
        useCache
      )
      newData = data
      hasNextPage = !!next
      total = count
      break
    }
    case 'prompt': {
      const { data, next, count } = await Prompt.list(
        queryParams,
        undefined,
        undefined,
        customEndpoint,
        abortController,
        useCache
      )
      newData = data
      hasNextPage = !!next
      total = count
      break
    }
    case 'course': {
      const { data, next, count } = await Course.list(
        queryParams,
        undefined,
        undefined,
        customEndpoint,
        abortController,
        useCache
      )
      newData = data
      hasNextPage = !!next
      total = count
      break
    }
    case 'learningpath': {
      const { data, next, count } = await LearningPath.list(
        queryParams,
        undefined,
        undefined,
        customEndpoint,
        abortController,
        useCache
      )
      newData = data
      hasNextPage = !!next
      total = count
      break
    }
    case 'opportunity': {
      const { data, next, count } = await SfOpportunity.list(
        queryParams,
        undefined,
        undefined,
        customEndpoint,
        abortController,
        useCache
      )
      newData = data
      hasNextPage = !!next
      total = count
      break
    }
    case 'call': {
      const { data, next, count } = await Call.list(
        { ...queryParams, omit: 'transcript' },
        undefined,
        undefined,
        customEndpoint,
        abortController,
        useCache
      )
      newData = data
      hasNextPage = !!next
      total = count
      break
    }
    case 'asset': {
      const { data, next, count } = await Asset.list(
        { ...queryParams, expand: 'analytics' },
        undefined,
        undefined,
        customEndpoint,
        abortController,
        useCache
      )
      newData = data
      hasNextPage = !!next
      total = count
      break
    }
    case 'hub': {
      const { data, next, count } = await Hub.list(
        queryParams,
        undefined,
        undefined,
        customEndpoint,
        abortController,
        useCache
      )
      newData = data
      hasNextPage = !!next
      total = count
      break
    }
    case 'page': {
      const { data, next, count } = await Page.list(
        queryParams,
        undefined,
        undefined,
        customEndpoint,
        abortController,
        useCache
      )
      newData = data
      hasNextPage = !!next
      total = count
      break
    }
    case 'coachingmoment': {
      const { data, next, count } = await CoachingMoment.list(
        queryParams,
        undefined,
        undefined,
        customEndpoint,
        abortController,
        useCache
      )
      newData = data
      hasNextPage = !!next
      total = count
      break
    }
    case 'dealroom': {
      const { data, next, count } = await DealRoom.list(
        { ...queryParams, expand: 'analytics' },
        undefined,
        undefined,
        customEndpoint,
        abortController,
        useCache
      )
      newData = data
      hasNextPage = !!next
      total = count
      break
    }
    case 'draft': {
      const data = await APIProvider.getDraftData(queryParams, abortController)
      newData = data
      hasNextPage = false
      break
    }
  }
  return { data: newData, hasNextPage, total, customFields: newCustomFields }
}

export const getDeleteDialogContent = (obj: DeleteableElement) => {
  if (obj instanceof FeedPlaylist)
    return {
      title: 'Delete Collection?',
      content:
        'Are you sure you want to delete this collection? This will delete the collection, but will not delete the individual posts in the collection. You cannot undo this action.',
    }
  if (obj instanceof Prompt)
    return {
      title: 'Delete Prompt?',
      content:
        'Are you sure you want to delete this prompt? This will delete the prompt but will not delete any responses already created by the team. You cannot undo this action.',
    }
  if (obj instanceof FeedPost)
    return { title: 'Delete Post?', content: 'Are you sure you want to delete this post? You cannot undo this action.' }
  if (obj instanceof Course)
    return {
      title: 'Delete Course?',
      content: 'Are you sure you want to delete this course? You cannot undo this action.',
    }
  if (obj instanceof LearningPath)
    return {
      title: 'Delete Learning Path?',
      content:
        'Are you sure you want to delete this learning path? This will delete the learning path but will not delete any Posts, Collections, or Courses contained in this Learning Path. You cannot undo this action.',
    }
  if (obj instanceof Asset)
    return {
      title: 'Delete Asset?',
      content: 'Are you sure you want to delete this asset? You cannot undo this action.',
    }
  if (obj instanceof Hub)
    return {
      title: 'Delete Hub?',
      content:
        'Are you sure you want to delete this Hub? This will delete the hub but will not delete any contents inside this Hub. You cannot undo this action.',
    }
  if (obj instanceof Page)
    return {
      title: 'Delete Page?',
      content: 'Are you sure you want to delete this page? You cannot undo this action.',
    }
  if (obj instanceof AutomationRule)
    return {
      title: 'Delete Rule?',
      content: 'Are you sure you want to delete this rule? You cannot undo this action.',
    }
  if (obj instanceof Group)
    return {
      title: 'Delete Group?',
      content: `Are you sure you want to delete the group, ${obj.name}? This will not delete any users that are part of the group. The content specific to this group may not be accessible by these users anymore. You cannot undo this action.`,
    }
  if (obj instanceof Rubric)
    return {
      title: 'Delete Rubric?',
      content: 'Are you sure you want to delete this rubric? You cannot undo this action.',
    }
  if (obj instanceof CoachingMomentTrigger)
    return {
      title: 'Delete Coaching Moment Trigger?',
      content: 'Are you sure you want to delete this trigger? You cannot undo this action.',
    }
  if (obj instanceof Module)
    return {
      title: 'Delete Section?',
      content:
        'Are you sure you want to delete this section? All modules within this section will also be deleted. You cannot undo this action. If you would like to keep the modules within this section please move them to a different section.',
    }
  if (obj instanceof DealRoom) {
    return {
      title: 'Delete Deal Room?',
      content: 'Are you sure you want to delete this deal room? You cannot undo this action.',
    }
  }
  if (obj instanceof Template) {
    const contentType = obj.learningContent.getTagTitle()
    return {
      title: 'Delete Template?',
      content: `Are you sure you want to delete this template? This will not delete the associated ${contentType} or any ${pluralize(
        contentType,
        2
      )} created from this template.`,
    }
  }
  return { title: 'Delete Post?', content: 'Are you sure you want to delete this post? You cannot undo this action.' }
}

export const fetchCompletionData = async (objId: string, contentType: LearningContentType, queryParams?: object) => {
  let userProgressRes: {
    results: {
      aggregates: ProgressAggregateDataType
      progresses: UserCompletion[]
    }
    previous: string
    next: string
    count: number
    sentRecentQueueItemsReminder: boolean
  }
  switch (contentType) {
    case 'course':
      userProgressRes = await APIProvider.getCourseCompletionData(objId, queryParams)
      break
    case 'learningpath':
      userProgressRes = await APIProvider.getLearningPathCompletionData(objId, queryParams)
      break
    case 'playlist':
      userProgressRes = await APIProvider.getCollectionCompletionData(objId, queryParams)
      break
    case 'prompt':
      userProgressRes = await APIProvider.getPromptCompletionData(objId, queryParams)
      break
    case 'asset':
      userProgressRes = await APIProvider.getAssetCompletionData(objId, queryParams)
      break
    case 'feedpost':
      userProgressRes = await APIProvider.getFeedPostCompletionData(objId, queryParams)
      break

    default:
      break
  }
  return {
    ...userProgressRes,
    results: {
      ...userProgressRes.results,
      progresses: userProgressRes.results.progresses.map((item) => ({
        ...item,
        user: User.fromData(item.user),
        dueDate: item.dueDate ? moment(item.dueDate) : undefined,
      })),
    },
  }
}

export const exportContentPerformanceCSVData = async (queryParams: object) => {
  const exportTab = queryParams['tab']
  queryParams['archiveStatus'] = sharedDataStore.user.isFaasAdmin() ? 'live,archived' : 'live'
  queryParams = clearFalseyValues(humps.decamelizeKeys(queryParams))
  const headers = { Accept: 'text/csv' }
  const fileName = `${sharedDataStore.user.company.name}_flockjay_${exportTab}_content_performance`
  let res: AxiosResponse

  if (exportTab === 'external') {
    queryParams['expand'] = 'tags,hubs,groups,custom_fields,author.groups,author.access_role,author.email'
    res = await APIProvider.getSharedLinksDashboardData(queryParams, headers)
  } else {
    queryParams['expand'] = 'author.access_role,author.email,author.groups,tags,custom_fields,hubs,content_type,groups'
    res = await Asset.getInternalAssetDashboardData(queryParams, headers)
  }
  await handleCSVExportResponse(res, fileName)
}

export const exportSearchQueryCSVData = async (params: object) => {
  const queryParams = params ? humps.decamelizeKeys(params) : {}
  const res = await APIProvider.getSearchAutocompleteValues(queryParams, { Accept: 'text/csv' })
  await handleCSVExportResponse(res, `${sharedDataStore.user.company.name}_flockjay_search_query`)
}

export const exportCertificateCSVData = async (params: object) => {
  // cannot use `list` here because it will try to map response to `UserCertifiate` type
  const res = await FlockjayProvider(
    new Route(
      MethodTypes.GET,
      UserCertificate.formatActionEndpoint().path,
      undefined,
      { Accept: 'text/csv' },
      {
        fields: UserCertificate.ANALYTICS_FIELDS,
        expand: 'user,user.groups,user.email,learning_content,groups,tags,hubs',
        ...(params ? humps.decamelizeKeys(params) : {}),
      },
      undefined,
      { retries: 0 }
    )
  )
  await handleCSVExportResponse(res, `${sharedDataStore.user.company.name}_flockjay_certificates`)
}

export const getContentTabs = (tabs: (LearningContentType | LibraryCategory)[]) => {
  if (sharedDataStore.user.isFaasPublic()) return ['course', 'learningpath'] as LibraryCategory[]
  const { defaultContentTab } = sharedDataStore.user.company
  if (tabs[0] !== defaultContentTab && defaultContentTab) {
    tabs = [defaultContentTab, ...tabs.filter((tab) => tab !== defaultContentTab)]
  }
  // Move 'hub' to the first position if it exists
  if (tabs.includes('hub')) tabs = ['hub', ...tabs.filter((tab) => tab !== 'hub')]
  // Move 'favourites' to the first position if it exists
  if (tabs.includes('favourites')) tabs = ['favourites', ...tabs.filter((tab) => tab !== 'favourites')]

  if (sharedDataStore.user.isFaasPartner()) {
    tabs = tabs.filter((tab) => !['opportunity', 'call', 'managerPicks'].includes(tab))
  }
  if (!sharedDataStore.user.company.integratedCallOrganization) {
    tabs = tabs.filter((tab) => tab !== 'call')
  }
  if (!sharedDataStore.user.sfdcIntegrationEnabled()) {
    tabs = tabs.filter((tab) => tab !== 'opportunity')
  }
  return tabs
}

export const getFieldsParam = (contentType: LibraryCategory | LearningContentType | 'draft') => {
  switch (contentType) {
    case 'hub': {
      return ['id', 'author', 'name', 'thumbnail_url'].join(',')
    }
    case 'asset': {
      return [
        'id',
        'learning_content_id',
        'author',
        'title',
        'is_favourited',
        'progress',
        'thumbnail_url',
        'description',
        'sf_metadata',
        'file_type',
        'created_at',
        'last_updated',
        'content_type',
        'google_drive_metadata',
        'is_internal',
        'group_ids',
        'url_preview',
        'file_url',
        'prevent_duplicate',
        'analytics',
        'comment_count',
        'like_count',
        'expiry_date',
        'template_id',
      ].join(',')
    }
    case 'course': {
      return [
        'id',
        'learning_content_id',
        'author',
        'name',
        'is_favourited',
        'progress',
        'thumbnail_url',
        'description',
        'sf_metadata',
        'group_ids',
        'created_at',
        'last_updated',
        'expiry_date',
        'estimated_duration',
      ].join(',')
    }
    case 'learningpath': {
      return [
        'id',
        'learning_content_id',
        'author',
        'title',
        'is_favourited',
        'progress',
        'thumbnail_url',
        'description',
        'sf_metadata',
        'group_ids',
        'created_at',
        'last_updated',
        'expiry_date',
        'estimated_duration',
      ].join(',')
    }
    case 'playlist': {
      return [
        'id',
        'learning_content_id',
        'author',
        'name',
        'is_favourited',
        'progress',
        'thumbnail_url',
        'description',
        'content_count',
        'group_ids',
        'created_at',
        'last_updated',
        'expiry_date',
      ].join(',')
    }
    case 'feedpost': {
      return [
        'id',
        'learning_content_id',
        'author',
        'title',
        'is_favourited',
        'progress',
        'thumbnail_url',
        'body',
        'sf_metadata',
        'video_metadata',
        'group_ids',
        'comment_count',
        'like_count',
        'created_at',
        'last_updated',
        'expiry_date',
      ].join(',')
    }
    case 'opportunity': {
      return ['id', 'name', 'opportunity_name', 'stage_name', 'content_count'].join(',')
    }
    case 'call': {
      return [
        'id',
        'learning_content_id',
        'user',
        'title',
        'is_favourited',
        'progress',
        'sf_metadata',
        'duration',
        'parties',
        'source',
        'group_ids',
      ].join(',')
    }
    case 'prompt': {
      return [
        'id',
        'learning_content_id',
        'author',
        'question',
        'is_favourited',
        'progress',
        'thumbnail_url',
        'description',
        'content_count',
        'group_ids',
        'created_at',
        'last_updated',
        'expiry_date',
      ].join(',')
    }
    case 'dealroom': {
      return [
        'id',
        'title',
        'content',
        'shared_content_id',
        'author',
        'group_ids',
        'is_favourited',
        'expiry_date',
        'thumbnail_url',
        'sf_metadata',
        'learning_content_id',
        'created_at',
        'last_updated',
        'analytics',
        'template_id',
      ].join(',')
    }
    default:
      return undefined
  }
}

const confettiModalKey = (obj: Course | LearningContent) => `${getFeedContentType(obj)}-${obj.id}`

export const hasOpenedConfettiModal = (obj: Course | LearningPath) => {
  return (localStorage.getItem(CONFETTI_LOCALSTORAGE_KEY) ?? '').includes(confettiModalKey(obj))
}

export const confettiModalOpened = (obj: Course | LearningPath) => {
  localStorage.setItem(
    CONFETTI_LOCALSTORAGE_KEY,
    `${localStorage.getItem(CONFETTI_LOCALSTORAGE_KEY) ?? ''},${confettiModalKey(obj)}`
  )
}

export const resetConfettiModal = (obj: Course | LearningPath) => {
  const newValue = (localStorage.getItem(CONFETTI_LOCALSTORAGE_KEY) || '')
    .split(',')
    .filter((val) => val !== confettiModalKey(obj))
  localStorage.setItem(CONFETTI_LOCALSTORAGE_KEY, newValue.join(','))
}

export const includesOneOfContentType = (
  learningContentTypes: LearningContentType[],
  objs: (LearningContent | FeedTask | CoachingMoment | Hub | Page)[]
) => {
  return objs.some((obj) => learningContentTypes.includes(getFeedContentType(obj)))
}
