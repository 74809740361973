import { MenuProps, Dropdown, DropdownProps } from 'antd'
import React, { useMemo } from 'react'
import {
  Archive,
  Columns,
  Copy,
  CornerUpRight,
  Edit3,
  ExternalLink,
  Eye,
  EyeOff,
  Link2,
  List,
  RefreshCw,
  Share2,
  Star,
  Trash,
} from 'react-feather'
import { ILibraryCardProps } from 'src/components/Library/LibraryCard'
import { Colors } from 'src/constants/colors'
import { getMenuContext } from 'src/utils/getMenuContext'
import { Asset } from 'src/models/Asset'
import { ContentTypeIcon, getFeedContentTypeName } from 'src/utils/content'
import StarFilled from 'src/assets/icons/StarFilledYellow.svg'
import { observer } from 'mobx-react'
import { Course } from 'src/models/Course'
import { LearningPath } from 'src/models/LearningPath'
import EditDriveIcon from 'src/assets/icons/edit-drive.svg'
import { Template } from 'src/models/Template'
import { apiFileUrlToFJFileUrl, copyToClipboard } from 'src/utils/format'

interface ContentMenuProps extends Omit<DropdownProps, 'menu' | 'trigger'> {
  obj: ILibraryCardProps['obj']
  insideDraft?: boolean
  insidePromotedSearch?: boolean
  hideEdit?: boolean
  hideDuplicate?: boolean
  hideAddToCollection?: boolean
  hideAddToFavorite?: boolean
  hideAddToQueue?: boolean
  hideShareExternally?: boolean
  onArchiveSuccess?(): void
  onDeleteSuccess?(obj?: ILibraryCardProps['obj']): void
  onEditSuccess?(obj: ILibraryCardProps['obj']): void
  onHidePostSuccess?: () => void
  onEditDriveFileSuccess?: (asset: Asset) => void
  onCreateTemplateSuccess?: (template: Template) => void
  handlePromotedSearchChangeSuccess?: () => Promise<void>
}

export const ContentMenu: React.FC<ContentMenuProps> = observer(
  ({
    children,
    obj,
    insideDraft,
    insidePromotedSearch,
    hideEdit,
    hideDuplicate,
    hideAddToCollection,
    hideAddToQueue,
    hideAddToFavorite,
    hideShareExternally,
    onArchiveSuccess,
    onDeleteSuccess,
    onEditSuccess,
    onHidePostSuccess,
    handlePromotedSearchChangeSuccess,
    onEditDriveFileSuccess,
    onCreateTemplateSuccess,
  }: ContentMenuProps) => {
    const {
      targetObj,
      canAddToQueue,
      canAddToFavourites,
      canEdit,
      canAddToCollection,
      canAddToLearningPath,
      canAddToHub,
      canAddToPromotedSearch,
      canRemoveFromPromotedSearch,
      canDelete,
      canDuplicate,
      canArchive,
      canOpenDriveFile,
      canEditDriveFile,
      canResyncFromDrive,
      canHide,
      canShareExternally,
      canOpenAnalyticsModal,
      canCreateTemplate,
      handleAddToPromotedSearch,
      handleRemoveFromPromotedSearch,
      handleLinkCopy,
      handleResyncFromDrive,
      handleToggleFavourite,
      handleArchiveContent,
      handleDuplicateContent,
      handleEditContent,
      handleOpenDeleteModal,
      handleOpenAddToHubModal,
      handleOpenHideFromFeedModal,
      handleOpenAddToPlaylistModal,
      handleOpenAddToLearningPathModal,
      handleOpenAddToQueueModal,
      handleShareExternally,
      handleSharePublicly,
      handleOpenAnalyticsModal,
      handleEditDriveFile,
      handleCreateTemplate,
    } = getMenuContext(obj, {
      insidePromotedSearch,
      hideEdit,
      hideDuplicate,
      hideAddToCollection,
      hideAddToFavorite,
      hideAddToQueue,
      hideShareExternally,
      onArchiveSuccess,
      onDeleteSuccess,
      onEditSuccess,
      onHidePostSuccess,
      handlePromotedSearchChangeSuccess,
      onEditDriveFileSuccess,
      onCreateTemplateSuccess,
    })

    const editMenuOption = useMemo(
      () =>
        canEdit
          ? {
              key: 'edit',
              label: 'Edit',
              onClick: handleEditContent,
              icon: <Edit3 size={20} />,
            }
          : null,
      [canEdit, handleEditContent]
    )

    const deleteMenuOption = useMemo(
      () =>
        canDelete
          ? {
              className: 'danger-menu',
              key: 'delete',
              label: 'Delete',
              onClick: handleOpenDeleteModal,
              icon: <Trash size={20} />,
            }
          : null,
      [canDelete, handleOpenDeleteModal]
    )

    const cardMenuContent: MenuProps = useMemo(() => {
      return {
        items: insideDraft
          ? [editMenuOption, deleteMenuOption]
          : [
              canHide
                ? {
                    key: 'hide-button',
                    label: 'Hide from Feed',
                    onClick: handleOpenHideFromFeedModal,
                    icon: <EyeOff size={20} />,
                  }
                : null,
              {
                key: 'share-internally',
                label: `Copy ${getFeedContentTypeName({ obj: targetObj, upperCase: true })} Link`,
                onClick: handleLinkCopy,
                icon: <Link2 size={20} />,
              },
              (targetObj instanceof Course || targetObj instanceof LearningPath) && targetObj.userCertificatePdfUrl
                ? {
                    key: 'copy-certificate-link',
                    label: 'Copy Certificate Link',
                    onClick: () =>
                      copyToClipboard(
                        apiFileUrlToFJFileUrl(targetObj.userCertificatePdfUrl),
                        undefined,
                        `${targetObj.title} Certificate Link`
                      ),
                    icon: <Link2 size={20} />,
                  }
                : null,
              canShareExternally
                ? {
                    key: 'share-externally',
                    label: 'Share Externally',
                    onClick: handleShareExternally,
                    icon: <ExternalLink size={20} />,
                  }
                : null,
              (targetObj instanceof Course || targetObj instanceof LearningPath) && targetObj.canSharePublicly()
                ? {
                    key: 'share-publicaly',
                    label: 'Share Publicly',
                    onClick: handleSharePublicly,
                    icon: <Share2 size={20} />,
                  }
                : null,
              canOpenAnalyticsModal
                ? {
                    key: 'open-analytics-modal',
                    label: 'Analytics',
                    onClick: handleOpenAnalyticsModal,
                    icon: <Eye color={Colors.cornflowerBlue} size={20} />,
                  }
                : null,
              canOpenDriveFile || canEditDriveFile || canResyncFromDrive ? { type: 'divider' } : null,
              targetObj instanceof Asset && canOpenDriveFile
                ? {
                    key: 'open-in-drive',
                    label: 'Open in Drive',
                    onClick: () => window.open(targetObj.googleDriveMetadata['url'], '_blank'),
                    icon: <CornerUpRight size={20} />,
                  }
                : null,
              canEditDriveFile
                ? {
                    key: 'edit-drive-file',
                    label: 'Edit Drive File',
                    onClick: handleEditDriveFile,
                    icon: <img src={EditDriveIcon} alt="" width={20} />,
                  }
                : null,
              canResyncFromDrive
                ? {
                    key: 'update-gdrive-button',
                    label: 'Re-Sync from Drive',
                    onClick: handleResyncFromDrive,
                    icon: <RefreshCw size={20} />,
                  }
                : null,
              canAddToFavourites || canAddToQueue || canAddToCollection || canAddToLearningPath || canAddToHub
                ? { type: 'divider' }
                : null,
              canAddToFavourites
                ? {
                    key: 'add-to-favourites',
                    label: targetObj.isFavourited ? 'Remove from Favorites' : 'Add to Favorites',
                    onClick: handleToggleFavourite,
                    icon: targetObj.isFavourited ? (
                      <img src={StarFilled} alt="StarFilled" width={20} height={20} />
                    ) : (
                      <Star color={Colors.cornflowerBlue} size={20} />
                    ),
                  }
                : null,
              canAddToQueue
                ? {
                    key: 'add-to-tasks',
                    label: 'Add to Tasks',
                    onClick: handleOpenAddToQueueModal,
                    icon: <List color={Colors.cornflowerBlue} size={20} />,
                  }
                : null,
              canAddToCollection
                ? {
                    key: 'add-to-collection',
                    label: 'Add to Collection',
                    onClick: handleOpenAddToPlaylistModal,
                    icon: <ContentTypeIcon contentType="playlist" size={20} />,
                  }
                : null,
              canAddToLearningPath
                ? {
                    key: 'add-to-learningpath',
                    label: 'Add to Learning Path',
                    onClick: handleOpenAddToLearningPathModal,
                    icon: <ContentTypeIcon contentType="learningpath" size={20} />,
                  }
                : null,
              canAddToHub
                ? {
                    key: 'add-to-hub',
                    label: 'Add to Hub',
                    onClick: handleOpenAddToHubModal,
                    icon: <ContentTypeIcon contentType="hub" size={20} />,
                  }
                : null,
              canAddToPromotedSearch || canRemoveFromPromotedSearch ? { type: 'divider' } : null,
              canAddToPromotedSearch
                ? {
                    key: 'add-to-promoted-search',
                    label: 'Add to Promoted Search',
                    onClick: handleAddToPromotedSearch,
                    icon: <ContentTypeIcon contentType="prompt" size={20} />,
                  }
                : null,
              canRemoveFromPromotedSearch
                ? {
                    key: 'remove-from-promoted-search',
                    label: 'Remove from Promoted Search',
                    onClick: handleRemoveFromPromotedSearch,
                    icon: <ContentTypeIcon contentType="prompt" size={20} />,
                  }
                : null,
              canEdit || canDuplicate || canDelete || canCreateTemplate ? { type: 'divider' } : null,
              editMenuOption,
              canDuplicate
                ? {
                    key: 'duplicate',
                    label: 'Duplicate',
                    onClick: handleDuplicateContent,
                    icon: <Copy size={20} />,
                  }
                : null,
              canCreateTemplate
                ? {
                    key: 'markAsTemplate',
                    label: 'Mark as Template',
                    onClick: handleCreateTemplate,
                    icon: <Columns size={20} />,
                  }
                : null,
              canArchive
                ? {
                    key: 'archive',
                    label: 'Archive',
                    onClick: handleArchiveContent,
                    icon: <Archive size={20} />,
                  }
                : null,
              deleteMenuOption,
            ],
      }
    }, [
      insideDraft,
      canAddToCollection,
      canAddToFavourites,
      canAddToHub,
      canAddToLearningPath,
      canAddToPromotedSearch,
      canAddToQueue,
      canArchive,
      canDelete,
      canDuplicate,
      canEdit,
      canHide,
      canRemoveFromPromotedSearch,
      canOpenAnalyticsModal,
      canShareExternally,
      canOpenDriveFile,
      canResyncFromDrive,
      canEditDriveFile,
      canCreateTemplate,
      handleAddToPromotedSearch,
      handleArchiveContent,
      handleDuplicateContent,
      handleLinkCopy,
      handleOpenAddToHubModal,
      handleOpenAddToLearningPathModal,
      handleOpenAddToPlaylistModal,
      handleOpenAddToQueueModal,
      handleOpenHideFromFeedModal,
      handleShareExternally,
      handleSharePublicly,
      handleRemoveFromPromotedSearch,
      handleResyncFromDrive,
      handleToggleFavourite,
      handleOpenAnalyticsModal,
      handleEditDriveFile,
      handleCreateTemplate,
      targetObj,
      editMenuOption,
      deleteMenuOption,
    ])

    return (
      <Dropdown menu={cardMenuContent} trigger={['click', 'hover']}>
        {children}
      </Dropdown>
    )
  }
)
