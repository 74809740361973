import { Course } from 'src/models/Course'
import { DataObject } from 'src/models/DataObject'
import { LearningPath } from 'src/models/LearningPath'
import { LearningContentType } from 'src/utils/content'
import { getFeedContentData } from 'src/utils/content'
import { User } from 'src/models/User'
import moment from 'moment'

export class UserCertificate extends DataObject {
  static apiEndpoint: string = '/feed/user_certificate/'
  static OVERRIDE_MAPPINGS = {
    user: ({ user }) => (typeof user === 'object' ? User.fromData(user) : undefined),
    userId: ({ user }) => (typeof user === 'string' ? user : undefined),
    learningContent: (data) =>
      data.learningContent ? getFeedContentData(data.learningContent, data.contentType) : undefined,
  }

  static ANALYTICS_FIELDS = 'id,content_type,user,pdf_url,created_at,learning_content,custom_fields'

  id: string
  createdAt: moment.Moment
  lastUpdated: moment.Moment
  certificateId: string
  contentType: LearningContentType
  learningContent: Course | LearningPath
  pdfUrl: string
  userId: string
  user: User
  expiryDate?: moment.Moment
}
