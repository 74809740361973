import React from 'react'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { Row, Col } from 'antd'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import {
  FormHeaderText,
  FjFormItem,
  FjProfileImageUploader,
  FormActionButtons,
  ContainerDiv,
  FjAvatar,
} from 'src/components/Common'
import { combineValidations, isRequired, isFile, maxFileSize, acceptedFileTypes } from 'src/utils/validation'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'

interface ProfilePictureFormProps {
  canEdit: boolean
  onSuccess?(): void
  imgUrl?: string
  userId?: string
  onCancel?(): void
  submitButtonLabel?: string
  secondaryButtonLabel?: string
}

@observer
export class ProfilePictureForm extends React.Component<ProfilePictureFormProps> {
  @observable imageUrl?: string

  constructor(props: ProfilePictureFormProps) {
    super(props)
    makeObservable(this)
    this.imageUrl = this.props.imgUrl
  }

  cancel = () => {
    this.imageUrl = this.props.imgUrl
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  handleSubmit = async (data: any) => {
    try {
      await sharedAppStateStore.wrapAppLoading(sharedDataStore.user.saveProfileImg(data.profileImgUrl))
      if (this.props.onSuccess) this.props.onSuccess()
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  handleDelete = async () => {
    try {
      await sharedAppStateStore.wrapAppLoading(sharedDataStore.user.deleteProfileImg())
      this.imageUrl = undefined
      if (this.props.onSuccess) this.props.onSuccess()
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  render() {
    let formActionButtonProps = {}
    if (this.props.imgUrl && this.props.canEdit) {
      formActionButtonProps = {
        onDelete: this.handleDelete,
        deleteButtonLabel: 'Delete Profile Picture',
        confirmDeleteText: 'Are you sure you want to delete your profile picture?',
      }
    }
    return (
      <>
        <FormHeaderText heading={this.props.imgUrl ? 'Edit Profile Picture' : 'Add Profile Picture'} />
        {this.imageUrl && (
          <Row justify="center">
            <Col>
              <ContainerDiv marginVertical="20px">
                <FjAvatar size={150} src={this.imageUrl} author={sharedDataStore.user} />
              </ContainerDiv>
            </Col>
          </Row>
        )}
        {this.props.canEdit && (
          <Formik initialValues={{ profilePic: undefined }} onSubmit={this.handleSubmit} enableReinitialize>
            <Form>
              <FjFormItem
                name="profilePic"
                fieldtitle="Profile Picture*"
                validate={combineValidations(
                  isRequired,
                  isFile,
                  maxFileSize(4),
                  acceptedFileTypes('image/jpeg', 'image/png')
                )}
              >
                <FjProfileImageUploader
                  name="profilePic"
                  shape="round"
                  promptText={
                    this.imageUrl
                      ? 'Click or drag to upload a new profile picture'
                      : 'Click or drag to upload a profile picture'
                  }
                  valueChanged={(fileUrl: string) => (this.imageUrl = fileUrl)}
                />
              </FjFormItem>
              <FormActionButtons
                submitButtonLabel={this.props.submitButtonLabel}
                secondaryButtonLabel={this.props.secondaryButtonLabel}
                onCancel={this.cancel}
                {...formActionButtonProps}
              />
            </Form>
          </Formik>
        )}
      </>
    )
  }
}
