import React, { Component, useMemo } from 'react'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { AutoComplete, AutoCompleteProps, Col, ColProps, Row, Tooltip, Upload } from 'antd'
import { UploadProps as $UploadProps } from 'antd/lib/upload'
import ImgCrop from 'antd-img-crop'
import { Field, FieldProps } from 'formik'
import { Form, Input, Select, Radio, Checkbox, DatePicker, AutoComplete as FormikAutoComplete } from 'formik-antd'
import { FormikFieldProps } from 'formik-antd/src/FieldProps'
import { DatePickerProps as $DatePickerProps } from 'antd/lib/date-picker'
import { RadioProps } from 'antd/lib/radio'
import { FormItemProps } from 'formik-antd/lib/form-item'
import { RadioGroupProps } from 'formik-antd/lib/radio'
import { InputProps, TextAreaProps } from 'formik-antd/lib/input'
import { SelectProps } from 'formik-antd/lib/select'
import { CheckboxProps, CheckboxGroupProps } from 'formik-antd/lib/checkbox'
import { FjText, Loader } from 'src/components/Common'
import { FontWeight, TextSize } from 'src/components/Common/Styles'
import { Colors } from 'src/constants/colors'
import { ContainerDiv } from 'src/components/Common'
import { formatPhoneNumber } from 'src/utils/format'
import { X, Check, Info, ChevronDown, Trash } from 'react-feather'
import { uploadToS3 } from 'src/utils/S3Upload'
import { sharedAppStateStore } from 'src/store/AppStateStore'

const { Option } = Select

interface FJStyledInputProps extends InputProps {
  backgroundColor?: string
  fontSize?: string
}

export const FJStyledInput = styled(
  React.forwardRef<HTMLInputElement, FJStyledInputProps>(({ type, backgroundColor, fontSize, ...rest }, ref) => {
    if (type === 'password') return <Input.Password ref={ref} {...rest} />
    return <Input ref={ref} {...rest} />
  })
)`
  text-align: left;
  color: ${Colors.outerSpace};
  border: solid 1px ${({ style }) => style?.borderColor || Colors.sharkOpacity12} !important;
  box-shadow: none !important;
  background-color: ${(props) => props.backgroundColor || Colors.white} !important;
  border-radius: 8px !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.28px;
  height: ${(props) => (props.size === 'small' ? '26px' : '38px')};
  outline: none;
  :focus {
    border: solid 1px ${Colors.cornflowerBlue} !important;
  }
  :placeholder {
    font-size: ${(props) => props.fontSize || '14px'} !important;
  }
  :disabled {
    color: ${Colors.cottonSeed} !important;
  }
`

export const FJStyledAutoComplete = styled(FormikAutoComplete)`
  .ant-select-selection-placeholder {
    height: 38px;
    line-height: 38px !important;
  }
`

const FJStyledSelect = styled(Select)`
  .ant-select-selector {
    padding: 4px 10px !important;
    border-radius: 10px !important;
    font-size: 14px;
    height: 38px !important;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${(props) => props.style?.backgroundColor ?? Colors.white};
  }
`

const FJStyledOption = styled(Option)`
  .ant-select-item-option {
    color: ${Colors.white};
    background-color: ${Colors.outerSpace};
  }
`

const LeftRoundedStyledInput = styled(FJStyledInput)`
  height: 55px;
  width: 70%;
  border: 2px solid ${Colors.neonCarrot};
  border-radius: 5px 0 0 5px;
`

export const FJStyledTextArea = styled(Input.TextArea)`
  text-align: left;
  border-radius: 8px !important;
  font-family: 'Satoshi', sans-serif;
  font-size: 14px;
  min-height: 38px;
  outline: none;
  :placeholder {
    font-size: 14px;
  }
  :disabled {
    color: ${Colors.rollingStone} !important;
  }
`

export const FjTaskInput = styled(Input)`
  border: none !important;
  margin-right: 9px !important;
  background: transparent !important;
`

interface FjFormItemProps extends FormItemProps {
  fieldtitle?: string | JSX.Element
  subtitle?: string
  tiptitle?: string
  titlecolor?: string
  tipIcon?: JSX.Element
}

export const FjFormItem: React.FC<FjFormItemProps> = ({ fieldtitle, subtitle, tiptitle, style, tipIcon, ...props }) => {
  return (
    <Form.Item {...props} style={{ ...style, textAlign: 'left' }} hasFeedback={false}>
      {(fieldtitle || subtitle) && (
        <div style={{ marginBottom: '8px' }}>
          <ContainerDiv textAlign="left" display="flex" alignItems="center" gap="5px">
            <FjText
              color={props.titlecolor ?? Colors.tapa}
              lineHeight="22px"
              fontWeight="bold500"
              letterSpacing="0.28px"
            >
              {fieldtitle}
            </FjText>
            {tiptitle && (
              <Tooltip placement="bottomLeft" title={tiptitle} arrowPointAtCenter>
                {tipIcon || <Info size="1rem" />}
              </Tooltip>
            )}
          </ContainerDiv>
          {!!subtitle && (
            <FjText display="inline-block" color={Colors.tapa} fontSize="small" marginBottom>
              {subtitle}
            </FjText>
          )}
        </div>
      )}
      {props.children}
    </Form.Item>
  )
}

export const SmallFormItem: React.FC<FjFormItemProps> = ({ style, ...props }) => (
  <FjFormItem {...props} style={{ marginBottom: '10px', ...style }} />
)

// FjMemoFormItem is for preventing re-render when update form field outside of form
// React renders the FjFormItem and memoizes the result. Before the next render, if the new props are the same,
// React reuses the memoized result skipping the next rendering
export const FjMemoFormItem = React.memo(FjFormItem)

interface IFJInputProps extends InputProps {
  trimValue?: boolean
  backgroundColor?: string
  fontSize?: string
}

export const FjInput: React.FC<IFJInputProps> = ({ trimValue, backgroundColor, fontSize, ...props }) => {
  return (
    <Field name={props.name}>
      {(fieldProps) => {
        const { touched, error } = fieldProps.meta
        return (
          <FJStyledInput
            onBlur={(e) => {
              if (trimValue && e.target.value) fieldProps.form.setFieldValue(props.name, e.target.value.trim())
            }}
            backgroundColor={backgroundColor}
            fontSize={fontSize}
            status={touched && error ? 'error' : undefined}
            {...props}
          />
        )
      }}
    </Field>
  )
}

interface FjSelectProps extends SelectProps {
  optionsMap?: Map<string, string>
}

export const FjSelect: React.FC<FjSelectProps> = ({ children, optionsMap, style, ...props }) => {
  return (
    <FJStyledSelect {...props} style={{ ...style }} suffixIcon={<ChevronDown size={20} style={{ minWidth: 20 }} />}>
      {optionsMap
        ? Array.from(optionsMap, ([key, value]) => (
            <FJStyledOption key={key} value={key}>
              {value}
            </FJStyledOption>
          ))
        : children}
    </FJStyledSelect>
  )
}

interface ILeftRoundedInputProps extends InputProps {
  outlineColor?: string
}

export const LeftRoundedInput: React.FC<ILeftRoundedInputProps> = ({ outlineColor, ...props }) => {
  const color = outlineColor || Colors.neonCarrot
  return (
    <LeftRoundedStyledInput
      {...props}
      style={{
        width: '70%',
        border: `2px solid ${color}`,
        borderRadius: '5px 0 0 5px',
        fontSize: '16px',
      }}
    />
  )
}

export const FjTextArea: React.FC<TextAreaProps> = (props) => {
  return <FJStyledTextArea {...props} />
}

interface FjRadioGroupProps extends RadioGroupProps {
  optionsMap?: Map<string, string>
}
export const FjRadioGroup: React.FC<FjRadioGroupProps> = ({ optionsMap, children, ...props }) => {
  return (
    <Radio.Group {...props}>
      {optionsMap
        ? Array.from(optionsMap, ([key, value]) => (
            <FjRadio name={props.name} key={key} value={key}>
              {value}
            </FjRadio>
          ))
        : children}
    </Radio.Group>
  )
}

const FjStyledRadio = styled(Radio)`
  display: flex;
  height: 100%;
  & .ant-radio-inner::after {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: -12px;
  }

  & .ant-radio-checked .ant-radio-inner {
    width: 18px;
    height: 18px;
  }
`

const FjCheckRadio = styled(FjStyledRadio)`
  & .ant-radio-inner::after {
    background-color: ${Colors.apple}!important;
  }

  & .ant-radio-checked .ant-radio-inner {
    border-color: ${Colors.apple}!important;
  }
`

const FjXRadio = styled(FjStyledRadio)`
  & .ant-radio-inner::after {
    background-color: ${Colors.scarlet}!important;
  }

  & .ant-radio-checked .ant-radio-inner {
    border-color: ${Colors.scarlet}!important;
  }
`

interface FjRadioProps extends RadioProps {
  radioType?: 'default' | 'green' | 'red'
}

export const FjRadio: React.FC<FjRadioProps & RadioGroupProps> = ({ radioType = 'default', ...props }) => {
  /**
   * TODO:
   * primary should be renamed or made default --
   * It is not now because FjStyledRadio defaults to display: block instead of inline-block when built for production.
   * This is not the behaviour we want
   */
  if (radioType === 'green') return <FjCheckRadio {...props} />
  if (radioType === 'red') return <FjXRadio {...props} />
  else return <FjStyledRadio {...props} />
}

export const FjRadioButton: React.FC<RadioGroupProps> = (props) => {
  return <Radio.Button {...props} />
}

export const FjBooleanRadioGroup: React.FC<RadioGroupProps> = (props: RadioGroupProps) => {
  return (
    <FjRadioGroup {...props}>
      <FjRadio name={props.name} value={true}>
        Yes
      </FjRadio>
      <FjRadio name={props.name} value={false}>
        No
      </FjRadio>
    </FjRadioGroup>
  )
}

export const FjCheckRadioButton = styled(Radio.Button)`
  &.ant-radio-button-wrapper {
    border-radius: 5px !important;
    height: 36px !important;
    width: 36px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Colors.hawkesBlue} !important;
  }
  &.ant-radio-button-wrapper-checked {
    background: ${Colors.emerald}!important;
  }

  .ant-radio-button-wrapper-disabled {
    background: ${Colors.hawkesBlue}!important;
  }
`

export const FjXRadioButton = styled(Radio.Button)`
  &.ant-radio-button-wrapper {
    border-radius: 5px !important;
    height: 36px !important;
    width: 36px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Colors.hawkesBlue};
  }
  &.ant-radio-button-wrapper-checked {
    background: ${Colors.sunsetOrange}!important;
  }

  .ant-radio-button-wrapper-disabled {
    background: ${Colors.hawkesBlue}!important;
  }
`

// RadioButtonGroup for lxp binary question type score selection
export const FjBinaryRadioGroup: React.FC<RadioGroupProps> = (props: RadioGroupProps) => {
  return (
    <FjRadioGroup {...props} style={{ display: 'flex', flexDirection: 'row', gap: '5px' }} buttonStyle="solid">
      <FjCheckRadioButton name={props.name} value={1}>
        <Check size={16} />
      </FjCheckRadioButton>
      <FjXRadioButton name={props.name} value={0} style={{ marginLeft: '10px' }}>
        <X size={16} />
      </FjXRadioButton>
    </FjRadioGroup>
  )
}

export const FjSenaryRadioButton = styled(Radio.Button)`
  &.ant-radio-button-wrapper {
    background: ${Colors.hawkesBlue};
    border: 1px solid ${Colors.hawkesBlue}!important;
    font-weight: ${FontWeight.semiBold}!important;
    font-size: ${TextSize.small}!important;
    border-radius: 5px;
  }

  &.ant-radio-button-wrapper-checked {
    background: ${Colors.chambray}!important;
    color: white !important;
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${Colors.hawkesBlue}!important;
    color: ${Colors.cornflowerBlue}!important;
  }
`

export const FjSenaryRadioGroup: React.FC<RadioGroupProps> = (props: RadioGroupProps) => {
  return (
    <FjRadioGroup {...props} buttonStyle="solid">
      {Array(6)
        .fill(0)
        .map((val, index) => (
          <FjSenaryRadioButton key={index} name={props.name} value={index} style={{ marginRight: '10px' }}>
            {index}
          </FjSenaryRadioButton>
        ))}
    </FjRadioGroup>
  )
}

type FjStyledCheckboxProps = {
  size?: 'small' | 'large'
  color?: string
  backgroundColor?: string
  borderColor?: string
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
}

const FjStyledCheckbox = styled(
  ({ size, color, backgroundColor, borderColor, ...props }: CheckboxProps & FjStyledCheckboxProps) => (
    <Checkbox {...props} />
  )
)<CheckboxProps & FjStyledCheckboxProps>`
  align-items: ${(props) => props.alignItems || 'baseline'} !important;
  span:nth-child(2) {
    font-size: ${(props) => (props.size === 'small' ? '12px' : '14px')};
    font-weight: 500;
    color: ${Colors.shark};
  }
  height: 100%;
  .ant-checkbox-inner {
    border-radius: 3px;
    width: ${(props) => (props.size === 'small' ? '14px' : '18px')};
    height: ${(props) => (props.size === 'small' ? '14px' : '18px')};
  }
  .ant-checkbox-inner:hover {
    border-color: ${(props) => props.borderColor ?? 'inherit'}!important;
  }
  ${(props) =>
    !props.indeterminate
      ? `.ant-checkbox-inner::after {
          height: ${(props) => (props.size === 'small' ? '9.5px' : '11.5px')};
          width: ${(props) => (props.size === 'small' ? '6px' : '7.5px')};
          top: ${(props) => (props.size === 'small' ? '40.5%' : '42.5%')};
          left: ${(props) => (props.size === 'small' ? '20.5%' : '21.5%')};
        }`
      : ''}

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.backgroundColor};
    border-color: ${(props) => props.borderColor};
  }

  .ant-checkbox-checked::after {
    border-color: ${(props) => props.borderColor};
  }

  & .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props) => props.borderColor};
  }
`

export interface FjCheckboxProps extends CheckboxProps {
  checkboxType?: 'green' | 'red' | 'default'
  size?: 'small' | 'large'
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
}

export const FjCheckBox: React.FC<FjCheckboxProps> = ({ checkboxType = 'default', size = 'large', ...props }) => {
  const styledProps: FjStyledCheckboxProps = { size }
  switch (checkboxType) {
    case 'green':
      styledProps.backgroundColor = Colors.deYork
      styledProps.borderColor = Colors.deYork
      styledProps.color = Colors.deYork
      break
    case 'red':
      styledProps.backgroundColor = Colors.outrageousOrange
      styledProps.borderColor = Colors.outrageousOrange
      styledProps.color = Colors.outrageousOrange
      break
    default:
      styledProps.backgroundColor = Colors.cornflowerBlue
      styledProps.borderColor = Colors.cornflowerBlue
      styledProps.color = Colors.cornflowerBlue
      break
  }
  return <FjStyledCheckbox {...props} {...styledProps} />
}

interface FjCheckboxGroupProps extends CheckboxGroupProps {
  optionsMap?: Map<string, string | JSX.Element>
  checkboxStyle?: React.CSSProperties
  optionsMaxHeight?: number | string
  disabledOptionKeys?: string[]
  colProps?: ColProps
  checkBoxProps?: Omit<FjCheckboxProps, 'name'>
}

export const FjCheckboxGroup: React.FC<FjCheckboxGroupProps> = ({
  optionsMaxHeight,
  checkboxStyle,
  optionsMap,
  disabledOptionKeys = [],
  colProps,
  children,
  checkBoxProps,
  ...props
}) => {
  const checkboxes = useMemo(() => {
    if (optionsMap) {
      return Array.from(optionsMap, ([key, value]) => (
        <FjCheckBox
          name={props.name}
          key={key}
          value={key}
          style={{ marginLeft: '0px', marginRight: '8px', ...(checkboxStyle || {}) }}
          disabled={disabledOptionKeys.includes(key)}
          {...checkBoxProps}
        >
          {value}
        </FjCheckBox>
      ))
    }
    return []
  }, [checkboxStyle, props.name, optionsMap, disabledOptionKeys, checkBoxProps])

  return (
    <Checkbox.Group {...props}>
      <div
        style={{
          maxHeight: optionsMaxHeight ? optionsMaxHeight : 'unset',
          overflowY: optionsMaxHeight ? 'scroll' : 'inherit',
          overflowX: 'hidden',
        }}
      >
        {optionsMap ? (
          colProps ? (
            <Row gutter={[16, 16]}>
              {checkboxes.map((checkbox, index) => (
                <Col key={index} {...colProps}>
                  {checkbox}
                </Col>
              ))}
            </Row>
          ) : (
            checkboxes
          )
        ) : (
          children
        )}
      </div>
    </Checkbox.Group>
  )
}

export class FjEmailInput extends Component<InputProps> {
  handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>, fieldProps: FieldProps) => {
    if (this.props.onChange) this.props.onChange(e)
    fieldProps.form.setFieldValue(this.props.name, e.target.value.trim().toLowerCase())
  }

  render() {
    return (
      <Field name={this.props.name}>
        {(fieldProps: FieldProps) => (
          <FjInput placeholder="Email" {...this.props} onChange={(e) => this.handleEmailChange(e, fieldProps)} />
        )}
      </Field>
    )
  }
}

export class FjPhoneNumberInput extends Component<InputProps> {
  private keyPressed = ''

  render() {
    return (
      <Field name={this.props.name}>
        {(fieldProps: FieldProps) => (
          <FjInput
            placeholder="(000) 000-0000"
            {...this.props}
            onKeyDown={(e) => (this.keyPressed = e.key)}
            onChange={(e) =>
              fieldProps.form.setFieldValue(this.props.name, formatPhoneNumber(e.target.value, this.keyPressed))
            }
          />
        )}
      </Field>
    )
  }
}

type IFjDatePickerProps = FormikFieldProps & $DatePickerProps

export const FjDatePicker: React.FC<IFjDatePickerProps> = (props: IFjDatePickerProps) => {
  return (
    <Field name={props.name}>
      {(fieldProps: FieldProps) => {
        return (
          <DatePicker
            {...props}
            onChange={(date, dateString) => {
              fieldProps.form.setFieldValue(props.name, date || null)
              props.onChange?.(date, dateString)
            }}
          />
        )
      }}
    </Field>
  )
}

// File Upload

const { Dragger } = Upload

interface FjDraggerProps extends $UploadProps {
  promptText?: string
  children?: React.ReactNode
}

type IFjUploaderProps = FormikFieldProps & FjDraggerProps

// Currently only supports single file upload
@observer
class FjDragger extends React.Component<FjDraggerProps> {
  @observable fileList = []

  constructor(props: FjDraggerProps) {
    super(props)
    makeObservable(this)
  }

  render() {
    return (
      <Dragger
        fileList={this.fileList}
        {...this.props}
        multiple={this.props.multiple || false}
        beforeUpload={this.props.beforeUpload || (() => false)}
        onChange={(info) => {
          this.fileList = info.fileList.slice(-1)
          if (this.props.onChange) {
            this.props.onChange(info)
          }
        }}
        style={{ padding: '40px 0' }}
      >
        {this.props.children ? (
          this.props.children
        ) : (
          <p style={{ textAlign: 'center' }}>
            <FjText fontSize="medium">{this.props.promptText || 'Click or drag file to upload'}</FjText>
          </p>
        )}
      </Dragger>
    )
  }
}

export const FjDragUploader: React.FC<IFjUploaderProps> = (props: IFjUploaderProps) => {
  return (
    <Field name={props.name}>
      {(fieldProps: FieldProps) => {
        return (
          <FjDragger
            {...props}
            onChange={(info) => fieldProps.form.setFieldValue(props.name, info.file)}
            showUploadList={{ removeIcon: <Trash size={16} color={Colors.outrageousOrange} /> }}
          />
        )
      }}
    </Field>
  )
}

interface FjProfileImageUploaderProps {
  shape?: 'rect' | 'round'
  valueChanged: (fileUrl: string) => string | Promise<string>
}

type IFjProfileImageUploaderProps = IFjUploaderProps & FjProfileImageUploaderProps

export const FjProfileImageUploader: React.FC<IFjProfileImageUploaderProps> = ({ shape, valueChanged, ...props }) => {
  return (
    <Field name={props.name}>
      {(fieldProps: FieldProps) => {
        return (
          <ImgCrop cropShape={shape}>
            <FjDragger
              accept="image/png,image/jpeg,image/jpg"
              {...props}
              beforeUpload={async (file) => {
                const profileImgUrl = await sharedAppStateStore.wrapAppLoading(uploadToS3(file), 'Uploading...')
                valueChanged(profileImgUrl)
                // have to set the file here for validation to work
                fieldProps.form.setValues({ [props.name]: file, profileImgUrl })
                return false
              }}
            />
          </ImgCrop>
        )
      }}
    </Field>
  )
}

export const FJStyledCourseSelect = styled(Select)`
  &:focus {
    border: none;
    outline: none;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }
  & .ant-select {
    color: black !important;
  }
  & .ant-select-arrow {
    color: black !important;
  }
  &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
  }
  & .ant-select-selection-item {
    font-size: 18px;
    color: black !important;
    font-weight: 700;
  }
`

export const FjCourseSelect: React.FC<FjSelectProps> = ({ children, optionsMap, ...props }) => {
  return (
    <FJStyledCourseSelect {...props}>
      {optionsMap
        ? Array.from(optionsMap, ([key, value]) => (
            <Option key={key} value={key}>
              {value}
            </Option>
          ))
        : children}
    </FJStyledCourseSelect>
  )
}

interface LoadingAutoCompleteProps extends AutoCompleteProps {
  isLoadingOptions?: boolean
}

export const LoadingAutoComplete = ({ isLoadingOptions, style, ...restProps }: LoadingAutoCompleteProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <AutoComplete style={{ textAlign: 'left', ...style }} {...restProps} className="loading-autocomplete" />
      <ContainerDiv width="20px">{isLoadingOptions ? <Loader iconSize={24} /> : null}</ContainerDiv>
    </div>
  )
}
