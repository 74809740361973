export const ReferralCodeQueryKey = 'referral-code'

export const CONVERSATION_STATUS = {
  Active: 'Active',
  Archived: 'Archived',
}
export const PUBLIC_NAV_HEIGHT = 58

export const FJ_NAV_HEIGHT = 70

export const MIN_PASSWORD_LENGTH = 12

export const ONE_WEEK_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000
