import React from 'react'
import moment from 'moment'
import { FjFormItem, FjDatePicker } from 'src/components/Common'

export const ExpirationDateField = ({ onChange }: { onChange?: (value: moment.Moment | null) => void }) => {
  return (
    <FjFormItem
      name="expiryDate"
      fieldtitle="Expiration Date"
      tiptitle="Content will expire on the selected day at midnight"
    >
      <FjDatePicker
        placeholder="Expiration Date"
        name="expiryDate"
        format={'MMM Do, YYYY'}
        disabledDate={(date) => date < moment().startOf('day')}
        style={{ width: 280 }}
        onChange={(value) => onChange?.(value)}
      />
    </FjFormItem>
  )
}
